import _ from 'lodash'

import { FilterTag } from '../../components'

export const capitalize = (word) => {
  return word[0].toUpperCase() + word.substring(1, word.length)
}

export const lowercase = (word) => {
  return word[0].toLowerCase() + word.substring(1, word.length)
}

export const camelCase = (word) => {
  return lowercase(word.replace(/\s+/g, ''))
}

export const formatDecimals = (num) => {
  return Math.round(num * 100)
}

export const findHeight = () => {
  return window.innerHeight
}

export const findWidth = () => {
  return window.innerWidth
}

export const findHeightChart = () => {
  if (window.innerWidth - 0.2 * window.innerHeight - 6 > 1800) {
    return 1000
  } else return window.innerHeight * 0.83 - 82
}

export const getQuarter = (date) => {
  const month = date.getMonth() + 1
  return Math.ceil(month / 3)
}

export const yearQuarterDecimal = (date) => {
  const quarter = getQuarter(date)
  let decimal = 1
  if (quarter > 1) {
    decimal = (quarter - 1) * 0.25
  }
  return date.getFullYear() + decimal
}

/* MODAL FILTERS */
export const mapValuesToTitles = (valuesArr, dataArr) => {
  let selectedValuesAndTitles = []
  selectedValuesAndTitles = valuesArr.map((value) => dataArr.find((data) => data.value === value))
  return selectedValuesAndTitles
}

export const formatFIs = (arr) => {
  const dataArr = []
  const uniqueFIs = []
  arr.map(({ _id }) => {
    if (!uniqueFIs.includes(_id)) {
      uniqueFIs.push(_id)
      dataArr.push({ value: _id, label: _id })
    }
  })
  const finalArr = dataArr.sort((a, b) => (a.value > b.value ? 1 : -1))
  return finalArr
}

export const mapFilters = (arr, filterType) => {
  const array = arr.map((item, i) => {
    let obje = item
    let key = 'children'
    if (Object.prototype.hasOwnProperty.call(obje, key)) {
      const childrenData = []
      item.children.map((child, idx) => {
        let obj = {}
        obj.label = child.title
        obj.value = idx.toString()
        obj.category = filterType
        obj.key = `${filterType}-${child.title}-${i}`
        obj.dbName = child.title.toLowerCase()
        obj.length = item.children.length
        obj.parent = item.title
        childrenData.push(obj)
      })
      return {
        label: item.title,
        value: i.toString(),
        _id: item._id,
        category: filterType,
        key: `${filterType}-${i}`,
        dbName: item.dbName,
        children: childrenData,
        length: arr.length,
      }
    } else {
      return {
        label: item.title,
        value: i.toString(),
        _id: item._id,
        key: `${filterType}-${i}`,
        dbName: item.dbName,
        length: arr.length,
        category: filterType.substring(0, filterType.length - 1, 1),
      }
    }
  })

  return array
}

export const findSubTopicChildren = (array, label) => {
  const children = []
  for (let i = 0; i < array.length; i++) {
    if (label === array[i].label && array.length > 2 && array[i].label !== 'Customer' && array[i].label !== 'Product') {
      for (let j = 0; j < array[i].children.length; j++) {
        children.push(array[i].children[j])
      }
      return children
    }
  }
}

export const renderTags = (obj, key) => {
  let finalTagArr = []
  if (key === 'topics') {
    const color = 'var(--green-tag-color)'
    const backgroundColor = 'var(--green-tag-bkg)'
    const border = '1px solid var(--green-tag-color)'
    let serArr = _.isEmpty(obj.customerService) ? null : obj.customerService
    if (serArr) {
      serArr.map((item) => {
        const name = `Customer Service - ${item.label}`
        finalTagArr.push(<FilterTag key={`${item.label}-${item.value}`} name={name} tagStyle={{ color, backgroundColor, border }} open={true} />)
      })
    }
    let designArr = _.isEmpty(obj.designAndFunctionality) ? null : obj.designAndFunctionality
    if (designArr) {
      designArr.map((item) => {
        const name = `Design & Functionality - ${item.label}`
        finalTagArr.push(<FilterTag key={`${item.label}-${item.value}`} name={name} tagStyle={{ color, backgroundColor, border }} open={true} />)
      })
    }
    let feeArr = _.isEmpty(obj.feesAndIncentives) ? null : obj.feesAndIncentives
    if (feeArr) {
      feeArr.map((item) => {
        const name = `Fees & Incentives - ${item.label}`
        finalTagArr.push(<FilterTag key={`${item.label}-${item.value}`} name={name} tagStyle={{ color, backgroundColor, border }} open={true} />)
      })
    }
    let offArr = _.isEmpty(obj.productOfferings) ? null : obj.productOfferings
    if (offArr) {
      offArr.map((item) => {
        const name = `Product Offerings - ${item.label}`
        finalTagArr.push(<FilterTag key={`${item.label}-${item.value}`} name={name} tagStyle={{ color, backgroundColor, border }} open={true} />)
      })
    }
    let accArr = _.isEmpty(obj.accessibility) ? null : obj.accessibility
    if (accArr) {
      accArr.map((item) => {
        const name = `Accessibility - ${item.label}`
        finalTagArr.push(<FilterTag key={`${item.label}-${item.value}`} name={name} tagStyle={{ color, backgroundColor, border }} open={true} />)
      })
    }
    let brandArr = _.isEmpty(obj.brandEquity) ? null : obj.brandEquity
    if (brandArr) {
      brandArr.map((item) => {
        const name = `Brand Equity - ${item.label}`
        finalTagArr.push(<FilterTag key={`${item.label}-${item.value}`} name={name} tagStyle={{ color, backgroundColor, border }} open={true} />)
      })
    }
  } else if (key === 'attributes') {
    let color = 'var(--cyan-tag-color)'
    let backgroundColor = 'var(--cyan-tag-bkg)'
    let border = '1px solid var(--cyan-tag-color)'
    let custArr = _.isEmpty(obj.customer) ? null : obj.customer
    if (custArr) {
      custArr.map((item) => {
        const name = `Customer - ${item.label}`
        finalTagArr.push(<FilterTag key={`${item.label}-${item.value}`} name={name} tagStyle={{ color, backgroundColor, border }} open={true} />)
      })
    }
    let prodArr = _.isEmpty(obj.product) ? null : obj.product
    if (prodArr) {
      prodArr.map((item) => {
        const name = `Product - ${item.label}`
        finalTagArr.push(<FilterTag key={`${item.label}-${item.value}`} name={name} tagStyle={{ color, backgroundColor, border }} open={true} />)
      })
    }
  } else if (key === 'time') {
    let color = 'var(--geekblue-tag-color)'
    let backgroundColor = 'var(--geekblue-tag-bkg)'
    let border = '1px solid var(--geekblue-tag-color)'

    const yrArr = []
    const qtrArr = []
    const mthArr = []
    if (Array.isArray(obj[key])) {
      obj[key].map((time) => {
        if (!isNaN(parseInt(time))) yrArr.push(time)
        else if (time.charAt(0) === 'Q') qtrArr.push(time)
        else mthArr.push(time)
      })
    }
    if (yrArr.length > 0) {
      yrArr.map((item) => {
        const name = `Year - ${item}`
        finalTagArr.push(<FilterTag key={item} name={name} tagStyle={{ color, backgroundColor, border }} open={true} />)
      })
    }
    if (qtrArr.length > 0) {
      qtrArr.map((item) => {
        const name = `Quarter - ${item}`
        finalTagArr.push(<FilterTag key={item} name={name} tagStyle={{ color, backgroundColor, border }} open={true} />)
      })
    }
    if (mthArr.length > 0) {
      mthArr.map((item) => {
        const name = `Month - ${item}`
        finalTagArr.push(<FilterTag key={item} name={name} tagStyle={{ color, backgroundColor, border }} open={true} />)
      })
    }
  } else {
    const tagArr = obj[key]
    tagArr.map((item) => {
      let color
      let backgroundColor
      let border
      switch (key) {
        case 'sources':
          color = 'var(--red-tag-color)'
          backgroundColor = 'var(--red-tag-bkg)'
          border = '1px solid var(--red-tag-color)'
          break
        case 'classifications':
          color = 'var(--magenta-tag-color)'
          backgroundColor = 'var(--magenta-tag-bkg)'
          border = '1px solid var(--magenta-tag-color)'
          break
        case 'sizes':
          color = 'var(--purple-tag-color)'
          backgroundColor = 'var(--purple-tag-bkg)'
          border = '1px solid var(--purple-tag-color)'
          break
        default:
          break
      }
      const name = color === 'var(--green-tag-color)' ? `Topics - ${item.label}` : item.label
      finalTagArr.push(<FilterTag key={item.key} name={name} tagStyle={{ color, backgroundColor, border }} open={true} />)
    })
  }
  let resArr = new Set(finalTagArr)
  return resArr
}

export const checkForMultipleTopics = (cs, fai, po, acc, be, daf) => {
  if (_.isEmpty(cs) && _.isEmpty(fai) && _.isEmpty(po) && _.isEmpty(acc) && _.isEmpty(be) && _.isEmpty(daf)) return false
  if (!_.isEmpty(cs) && _.isEmpty(fai) && _.isEmpty(po) && _.isEmpty(acc) && _.isEmpty(be) && _.isEmpty(daf)) return false
  if (_.isEmpty(cs) && !_.isEmpty(fai) && _.isEmpty(po) && _.isEmpty(acc) && _.isEmpty(be) && _.isEmpty(daf)) return false
  if (_.isEmpty(cs) && _.isEmpty(fai) && !_.isEmpty(po) && _.isEmpty(acc) && _.isEmpty(be) && _.isEmpty(daf)) return false
  if (_.isEmpty(cs) && _.isEmpty(fai) && _.isEmpty(po) && !_.isEmpty(acc) && _.isEmpty(be) && _.isEmpty(daf)) return false
  if (_.isEmpty(cs) && _.isEmpty(fai) && _.isEmpty(po) && _.isEmpty(acc) && !_.isEmpty(be) && _.isEmpty(daf)) return false
  if (_.isEmpty(cs) && _.isEmpty(fai) && _.isEmpty(po) && _.isEmpty(acc) && _.isEmpty(be) && !_.isEmpty(daf)) return false

  return true
}

/* REVIEWS PAGE */
export const createReviewTitle = (str) => {
  const arr = str.split(' ')
  const getWords = (word, i) => {
    if (i < 6) return word
  }
  const firstSixWords = arr.filter(getWords)
  return firstSixWords.join(' ')
}

export const formatReviewFIs = (arr) => {
  const dataArr = []
  arr.map(({ _id }) => {
    dataArr.push({ value: _id, label: _id })
  })
  const finalArr = dataArr.sort((a, b) => (a.value > b.value ? 1 : -1))
  return finalArr
}

export const changeScoredKey = (arr) => {
  const changedData = arr.map((item) => {
    const { key, _id, pxi, mentionRate, totalReviews } = item
    return {
      '#': key,
      FinancialInstitution: _id,
      PXI: pxi,
      TopicMentionRate: mentionRate,
      TotalReviews: totalReviews,
    }
  })
  return changedData
}

/* change the key value */
export const changeKeyData = (arr) => {
  const changedData = arr.map((item) => {
    const { _id, index, rating, review_text, review_title, external_id, review_date, source } = item
    return {
      '#': index,
      Financial_Institution_AppNar: _id,
      Reviews_Starts: rating,
      Submission_Time: review_date,
      External_Id: external_id,
      Review_Title: review_title,
      Review_Text: review_text,
      Source: source,
    }
  })
  return changedData
}

export const formatReviews = (arr, isReviewCardExpanded) => {
  const newData = arr.map((review) => {
    const { _id, index, rating, review_date, review_text, review_title, username } = review
    return {
      _id,
      id: isReviewCardExpanded ? 'expanded' : 'collapsed',
      index,
      rating,
      review_date,
      review_text,
      review_title,
      username,
    }
  })
  return newData
}

/* DRIVERS PAGE */
export const formatDriversData = (arr) => {
  let direction = true
  const sumDriversData = (arr) => {
    let custServTotal = 0
    let custServPos = 0
    let custServNeg = 0
    let feesTotal = 0
    let feesPos = 0
    let feesNeg = 0
    let offTotal = 0
    let offNeg = 0
    let offPos = 0
    let accTotal = 0
    let accPos = 0
    let accNeg = 0
    let brandTotal = 0
    let brandPos = 0
    let brandNeg = 0
    let custTotal = 0
    let custPos = 0
    let custNeg = 0
    let prodTotal = 0
    let prodPos = 0
    let prodNeg = 0
    let endingTotal = 0
    let endingPos = 0
    let endingNeg = 0
    let startingTotal = 0
    let startingPos = 0
    let startingNeg = 0
    let designTotal = 0
    let designPos = 0
    let designNeg = 0
    arr.map((item) => {
      const { data } = item
      data.map((child) => {
        for (const x in child) {
          const { supporters, detractors, total } = child[x]
          switch (x) {
            case 'Design Functionality':
              designTotal += total
              designPos += supporters
              designNeg += detractors
              break
            case 'Customer Service':
              custServTotal += total
              custServPos += supporters
              custServNeg += detractors
              break
            case 'Fees And Incentives':
              feesNeg += detractors
              feesPos += supporters
              feesTotal += total
              break
            case 'Product Offerings':
              offTotal += total
              offPos += supporters
              offNeg += detractors
              break
            case 'Accessibility':
              accTotal += total
              accPos += supporters
              accNeg += detractors
              break
            case 'Brand Equity':
              brandTotal += total
              brandPos += supporters
              brandNeg += detractors
              break
            case 'Product':
              prodTotal += total
              prodPos += supporters
              prodNeg += detractors
              break
            case 'Customer':
              custTotal += total
              custPos += supporters
              custNeg += detractors
              break
            case 'Ending PXI':
              endingTotal += total
              endingPos += supporters
              endingNeg += detractors
              break
            case 'Starting PXI':
              startingTotal += total
              startingPos += supporters
              startingNeg += detractors
              break
          }
        }
      })
    })
    const finalArr = [
      {
        'Design Functionality': {
          supporters: designPos,
          detractors: designNeg,
          total: designTotal,
        },
      },
      {
        'Customer Service': {
          supporters: custServPos,
          detractors: custServNeg,
          total: custServTotal,
        },
      },
      {
        'Fees And Incentives': {
          supporters: feesPos,
          detractors: feesNeg,
          total: feesTotal,
        },
      },
      {
        'Product Offerings': {
          supporters: offPos,
          detractors: offNeg,
          total: offTotal,
        },
      },
      {
        Accessibility: {
          supporters: accPos,
          detractors: accNeg,
          total: accTotal,
        },
      },
      {
        'Brand Equity': {
          supporters: brandPos,
          detractors: brandNeg,
          total: brandTotal,
        },
      },
      {
        Customer: {
          supporters: custPos,
          detractors: custNeg,
          total: custTotal,
        },
      },
      {
        Product: {
          supporters: prodPos,
          detractors: prodNeg,
          total: prodTotal,
        },
      },
      {
        'Ending PXI': {
          supporters: endingPos,
          detractors: endingNeg,
          total: endingTotal,
        },
      },
      {
        'Starting PXI': {
          supporters: startingPos,
          detractors: startingNeg,
          total: startingTotal,
        },
      },
    ]

    return finalArr
  }
  const keys = _.keys(arr[0])
  let dataArr
  if (keys.includes('_id')) {
    dataArr = sumDriversData(arr)
  } else dataArr = arr

  //step 0 - sort the data
  let sortedData = []
  dataArr.map((item) => {
    for (const x in item) {
      switch (x) {
        case 'Starting PXI':
          sortedData[0] = item
          break
        case 'Brand Equity':
          sortedData[1] = item
          break
        case 'Accessibility':
          sortedData[2] = item
          break
        case 'Customer Service':
          sortedData[3] = item
          break
        case 'Product Offerings':
          sortedData[4] = item
          break
        case 'Fees And Incentives':
          sortedData[5] = item
          break
        case 'Design Functionality':
          sortedData[6] = item
          break
        case 'Ending PXI':
          sortedData[7] = item
          break
      }
    }
  })

  //step 1 - get rid of any 0 values in the response
  const data = []
  sortedData.map((item) => {
    for (const x in item) {
      if (typeof item[x] === 'object' && item[x].total !== 0) {
        const obj = {}
        obj[x] = item[x]
        data.push(obj)
      }
    }
  })

  //step 2 - calculate the PXI and formatted PXI
  data.map((item) => {
    for (const x in item) {
      const { detractors, total, supporters } = item[x]
      item[x].PXI = Math.round(((supporters - detractors) / total) * 100)
    }
  })
  //step 3 - create starting PXI and ending PXI objects, because their data will need to be referenced multiple times to calculate direction, impact, and adjustments
  let starting = {}
  let ending = {}
  data.map((item) => {
    for (const x in item) {
      if (x === 'Ending PXI') {
        ending = item[x]
      } else if (x === 'Starting PXI') {
        starting = item[x]
      }
    }
  })
  //step 4 - determine the direction of our waterfall chart where false is \ and true is /
  if (starting.PXI > ending.PXI) {
    direction = false
  }

  //step 5 - calculates impact for topic fields
  //impact = (topic PXI - ending PXI) * (topic total reviews / starting total reviews)
  data.map((item) => {
    for (const x in item) {
      if (x !== 'Ending PXI' && x !== 'Starting PXI') {
        const pxi = item[x].PXI - ending.PXI
        const totals = item[x].total / starting.total
        item[x].impact = pxi * totals
      }
    }
  })

  //step 6 - determine variance and sum of all topic total reviews
  //variance and impactTotals are needed for adjustment calculations
  let variance = starting.PXI - ending.PXI
  let impactTotals = 0
  data.map((item) => {
    for (const x in item) {
      if (x !== 'Ending PXI' && x !== 'Starting PXI') {
        impactTotals += item[x].impact
      }
    }
  })

  //step 7 - calculate adjustments for all topics
  //adjustments should add up to ~equal the variance
  data.map((item) => {
    for (const x in item) {
      if (x !== 'Ending PXI' && x !== 'Starting PXI') {
        const adjust = (item[x].impact / impactTotals) * variance
        item[x].adjustment = direction ? (Math.round(adjust * 100) / 100) * -1 : Math.round(adjust * 100) / 100
      }
    }
  })

  //step 8 - create the step
  //to render the waterfall chart correctly, the adjustment needs to be the difference between the 2 bars, so we need to create a 'step' figure, which is the positive integer of the adjustment
  //some adjustments are very small, between -1 and 1, so those are formatted with toFixed, whereas anything above one is formatted with Math.floor() to find the nearest integer
  data.map((item) => {
    for (const x in item) {
      if (x !== 'Ending PXI' && x !== 'Starting PXI') {
        item[x].step = Math.round(item[x].adjustment)
      }
    }
  })
  //step 9 - create the array with data now formatted for the chart
  //step 10 - always initialize with the Starting PXI
  let finalData = [
    {
      name: 'Starting PXI',
      PXI: +starting.PXI.toFixed(1),
      clearBar: 0,
    },
  ]

  //removes Starting PXI from the array
  data.shift()
  //step 11 - add the topics to the array
  //11.a - the first topic will get its PXI and clearBar based off of Starting PXI
  //11.b - the remaining topics will get its PXI and clearBar based off of the element before it
  //11.c - determine whether or not we're subtracting step or adding step based on direction
  for (let i = 0; i < data.length; i++) {
    let obj = {}
    for (const x in data[i]) {
      if (i === 0) {
        obj.name = x
        obj.PXI = +data[i][x].adjustment.toFixed(1)
        // obj.clearBar = +finalData[0].PXI.toFixed(1)
        obj.step = +data[i][x].adjustment.toFixed(1)
        obj.clearBar = finalData[i].PXI
        //obj.step = obj.PXI
        finalData.push(obj)
      } else if (x !== 'Ending PXI' && x !== 'Starting PXI') {
        obj.name = x
        obj.PXI = +data[i][x].adjustment.toFixed(1)
        obj.step = +data[i][x].adjustment.toFixed(1)
        // if direction is true you need to add the step to the clearBar
        if (direction) {
          obj.clearBar = +finalData[i].clearBar.toFixed(1) + +finalData[i].step.toFixed(1)
          //if it's false you need to subtract it
          //this is how you create the correct direction of the waterfall chart
        } else if (!direction) {
          obj.clearBar = +finalData[i].clearBar.toFixed(1) - +finalData[i].step.toFixed(1)
        }
        finalData.push(obj)
      }
    }
  }

  //step 12 - adding Ending PXI to the end of the array
  //note clearBar value will always be 0 for ending and starting
  finalData.push({ name: 'Ending PXI', PXI: +ending.PXI.toFixed(1), clearBar: 0 })

  //step 13 - return data - we did it!
  return { finalData, direction }
}

/* Topics for DRIVERS PAGE */
export const topicDriversData = (arr) => {
  // const sumDriversData = (arr) => {
  //   let custServTotal = 0
  //   let custServPos = 0
  //   let custServNeg = 0
  //   let feesTotal = 0
  //   let feesPos = 0
  //   let feesNeg = 0
  //   let offTotal = 0
  //   let offNeg = 0
  //   let offPos = 0
  //   let accTotal = 0
  //   let accPos = 0
  //   let accNeg = 0
  //   let brandTotal = 0
  //   let brandPos = 0
  //   let brandNeg = 0
  //   let custTotal = 0
  //   let custPos = 0
  //   let custNeg = 0
  //   let prodTotal = 0
  //   let prodPos = 0
  //   let prodNeg = 0
  //   let endingTotal = 0
  //   let endingPos = 0
  //   let endingNeg = 0
  //   let startingTotal = 0
  //   let startingPos = 0
  //   let startingNeg = 0
  //   let designTotal = 0
  //   let designPos = 0
  //   let designNeg = 0
  //   arr.map((item) => {
  //     const { data } = item
  //     data.map((child) => {
  //       for (const x in child) {
  //         const { supporters, detractors, total } = child[x]
  //         switch (x) {
  //           case 'Design Functionality':
  //             designTotal += total
  //             designPos += supporters
  //             designNeg += detractors
  //             break
  //           case 'Customer Service':
  //             custServTotal += total
  //             custServPos += supporters
  //             custServNeg += detractors
  //             break
  //           case 'Fees And Incentives':
  //             feesNeg += detractors
  //             feesPos += supporters
  //             feesTotal += total
  //             break
  //           case 'Product Offerings':
  //             offTotal += total
  //             offPos += supporters
  //             offNeg += detractors
  //             break
  //           case 'Accessibility':
  //             accTotal += total
  //             accPos += supporters
  //             accNeg += detractors
  //             break
  //           case 'Brand Equity':
  //             brandTotal += total
  //             brandPos += supporters
  //             brandNeg += detractors
  //             break
  //           case 'Product':
  //             prodTotal += total
  //             prodPos += supporters
  //             prodNeg += detractors
  //             break
  //           case 'Customer':
  //             custTotal += total
  //             custPos += supporters
  //             custNeg += detractors
  //             break
  //           case 'Ending PXI':
  //             endingTotal += total
  //             endingPos += supporters
  //             endingNeg += detractors
  //             break
  //           case 'Starting PXI':
  //             startingTotal += total
  //             startingPos += supporters
  //             startingNeg += detractors
  //             break
  //         }
  //       }
  //     })
  //   })
  //   const finalArr = [
  //     {
  //       'Design Functionality': {
  //         supporters: designPos,
  //         detractors: designNeg,
  //         total: designTotal,
  //       },
  //     },
  //     {
  //       'Customer Service': {
  //         supporters: custServPos,
  //         detractors: custServNeg,
  //         total: custServTotal,
  //       },
  //     },
  //     {
  //       'Fees And Incentives': {
  //         supporters: feesPos,
  //         detractors: feesNeg,
  //         total: feesTotal,
  //       },
  //     },
  //     {
  //       'Product Offerings': {
  //         supporters: offPos,
  //         detractors: offNeg,
  //         total: offTotal,
  //       },
  //     },
  //     {
  //       Accessibility: {
  //         supporters: accPos,
  //         detractors: accNeg,
  //         total: accTotal,
  //       },
  //     },
  //     {
  //       'Brand Equity': {
  //         supporters: brandPos,
  //         detractors: brandNeg,
  //         total: brandTotal,
  //       },
  //     },
  //     {
  //       Customer: {
  //         supporters: custPos,
  //         detractors: custNeg,
  //         total: custTotal,
  //       },
  //     },
  //     {
  //       Product: {
  //         supporters: prodPos,
  //         detractors: prodNeg,
  //         total: prodTotal,
  //       },
  //     },
  //     {
  //       'Ending PXI': {
  //         supporters: endingPos,
  //         detractors: endingNeg,
  //         total: endingTotal,
  //       },
  //     },
  //     {
  //       'Starting PXI': {
  //         supporters: startingPos,
  //         detractors: startingNeg,
  //         total: startingTotal,
  //       },
  //     },
  //   ]

  //   return finalArr
  // }
  // const keys = _.keys(arr[0])
  // let dataArr
  // if (keys.includes('_id')) {
  //   dataArr = sumDriversData(arr)
  // } else dataArr = arr

  //step 0 - sort the data

  let sortedData = []
  arr.map((item) => {
    for (const x in item) {
      switch (x) {
        case 'Allreviews PXI':
          sortedData[0] = item
          break
        case 'Starting PXI':
          sortedData[1] = item
          break
        case 'Brand Equity':
          sortedData[2] = item
          break
        case 'Accessibility':
          sortedData[3] = item
          break
        case 'Design And Functionality':
          sortedData[4] = item
          break
        case 'Customer Service':
          sortedData[5] = item
          break
        case 'Product Offerings':
          sortedData[6] = item
          break
        case 'Fees And Incentives':
          sortedData[7] = item
          break
        case 'Ending PXI':
          sortedData[8] = item
          break
      }
    }
  })

  //step 1 - get rid of any 0 values in the response
  const data = []
  sortedData.map((item) => {
    for (const x in item) {
      if (typeof item[x] === 'object' && item[x].total !== 0) {
        const obj = {}
        obj[x] = item[x]
        data.push(obj)
      }
    }
  })
  //step 2 - calculate the PXI and formatted PXI
  data.map((item) => {
    for (const x in item) {
      const { detractors, total, supporters } = item[x]
      item[x].PXI = (supporters - detractors) / total
    }
  })
  // let withoutPxiValue = data[1]['Starting PXI'].PXI
  let withoutPxiValue = data.find((myObj) => Object.hasOwn(myObj, 'Starting PXI'))['Starting PXI'].PXI
  // let allReviews = data[0]['Allreviews PXI'].total
  let allReviews = data.find((myObj) => Object.hasOwn(myObj, 'Allreviews PXI'))['Allreviews PXI'].total
  let finalData = []
  for (let i = 0; i < data.length; i++) {
    let obj = {}
    for (const x in data[i]) {
      if (x !== 'Allreviews PXI' && x !== 'Starting PXI') {
        obj.name = x
        obj.PXI = ((+data[i][x].PXI - withoutPxiValue) * (data[i][x].total / allReviews) * 100).toFixed(1)
        // obj.clearBar = +finalData[0].PXI.toFixed(1)
        // obj.step = +data[i][x].adjustment.toFixed(1)
        // obj.clearBar = finalData[i].PXI
        //obj.step = obj.PXI
        finalData.push(obj)
      } else if (x === 'Starting PXI') {
        obj.name = x
        obj.PXI = (+data[i][x].PXI * 100).toFixed(1)
        // obj.clearBar = +finalData[0].PXI.toFixed(1)
        // obj.step = +data[i][x].adjustment.toFixed(1)
        // obj.clearBar = finalData[i].PXI
        //obj.step = obj.PXI
        finalData.push(obj)
      }
    }
  }

  // finalData.map((item) => {
  //   for (const x in item) {
  //     if (x.name !== 'Ending PXI' && x.name !== 'Starting PXI') {
  //       item[x].step = Math.round(item[x].PXI)
  //     }
  //   }
  // })
  let EndingSum = 0
  for (let i = 0; i < finalData.length; i++) {
    EndingSum = parseFloat(finalData[i].PXI) + EndingSum
    if (i != 0) {
      finalData[i].step = finalData[i].PXI
      finalData[i].clearBar = parseFloat(finalData[i - 1].PXI) + parseFloat(finalData[i - 1].clearBar)
    } else {
      finalData[i].clearBar = 0
    }
    // EndingSum += finalData[i].PXI
  }
  let endingObj = {
    name: 'Ending PXI',
    PXI: EndingSum,
    clearBar: 0,
  }
  // let direction = true
  // if (finalData[0].PXI > EndingSum) {
  //   direction = false
  // }

  finalData.push(endingObj)
  return { finalData }
}

export const topicDriversData2 = (arr) => {
  const pxiInfo = {
    supporters: 0,
    detractors: 0,
    total: 0,
    PXI: 0,
  }
  const driversOrder = [
    'Starting PXI',
    'Brand Equity',
    'Accessibility',
    'Design And Functionality',
    'Customer Service',
    'Product Offerings',
    'Fees And Incentives',
    'Customer',
    'Product',
    'Ending PXI',
  ]
  let combinedData = driversOrder.map((item) => ({ [item]: { ...pxiInfo } }))
  // [
  //   { 'Starting PXI': { ...pxiInfo } },
  //   { 'Brand Equity': { ...pxiInfo } },
  //   { Accessibility: { ...pxiInfo } },
  //   { 'Design And Functionality': { ...pxiInfo } },
  //   { 'Customer Service': { ...pxiInfo } },
  //   { 'Product Offerings': { ...pxiInfo } },
  //   { 'Fees And Incentives': { ...pxiInfo } },
  //   { Customer: { ...pxiInfo } },
  //   { Product: { ...pxiInfo } },
  //   { 'Ending PXI': { ...pxiInfo } },
  // ]
  for (const x of arr) {
    for (const y of x.data) {
      for (const [key, value] of Object.entries(y)) {
        const { detractors, supporters, total } = value
        combinedData.find((myObj) => Object.hasOwn(myObj, key))[key].detractors += detractors
        combinedData.find((myObj) => Object.hasOwn(myObj, key))[key].total += total
        combinedData.find((myObj) => Object.hasOwn(myObj, key))[key].supporters += supporters
        if (total !== 0) {
          combinedData.find((myObj) => Object.hasOwn(myObj, key))[key].PXI = (supporters - detractors) / total
        } else {
          combinedData.find((myObj) => Object.hasOwn(myObj, key))[key].PXI = 0
        }
      }
    }
  }

  //step 0 - sort the data
  // let sortedData = []
  // combinedData.map((item) => {
  //   for (const x in item) {
  //     switch (x) {
  //       case 'Allreviews PXI':
  //         sortedData[0] = item
  //         break
  //       case 'Starting PXI':
  //         sortedData[1] = item
  //         break
  //       case 'Brand Equity':
  //         sortedData[2] = item
  //         break
  //       case 'Accessibility':
  //         sortedData[3] = item
  //         break
  //       case 'Design And Functionality':
  //         sortedData[4] = item
  //         break
  //       case 'Customer Service':
  //         sortedData[5] = item
  //         break
  //       case 'Product Offerings':
  //         sortedData[6] = item
  //         break
  //       case 'Fees And Incentives':
  //         sortedData[7] = item
  //         break
  //       case 'Ending PXI':
  //         sortedData[8] = item
  //         break
  //     }
  //   }
  // })

  //step 1 - get rid of any 0 values in the response
  const data = []
  combinedData.map((item) => {
    for (const x in item) {
      if (typeof item[x] === 'object' && item[x].total !== 0) {
        const obj = {}
        obj[x] = item[x]
        data.push(obj)
      }
    }
  })
  //step 2 - calculate the PXI and formatted PXI
  // data.map((item) => {
  //   for (const x in item) {
  //     const { supporters, detractors, total } = item[x]
  //     item[x].PXI = (supporters - detractors) / total
  //   }
  // })
  const startingPxi = data[0]['Starting PXI'].PXI
  const endingObj = data[data.length - 1]['Ending PXI']
  let finalData = []
  let impactTotal = 0
  for (let i = 0; i < data.length; i++) {
    let obj = {}
    for (const x in data[i]) {
      obj.name = x
      if (x === 'Starting PXI') {
        obj.clearBar = 0
      } else if (x !== 'Ending PXI' && x !== 'Starting PXI') {
        const impact = (+data[i][x].PXI - startingPxi) * (data[i][x].total / endingObj.total)
        impactTotal += impact
        obj.PXI = _.round(impact * 100, 1)
      } else if (x === 'Ending PXI') {
        obj.PXI = _.round(+data[i][x].PXI * 100, 1)
        obj.clearBar = 0
      }
      finalData.push(obj)
    }
  }
  if (!_.isEmpty(finalData)) {
    finalData[0].PXI = _.round((endingObj.PXI - impactTotal) * 100, 1)
    for (let i = 1; i < finalData.length - 1; i++) {
      finalData[i].clearBar = _.round(finalData[i - 1].PXI + finalData[i - 1].clearBar, 1)
    }
  }
  return { finalData }
}

/* TRENDS PAGE */
export const formatQuarterlyAggData = (arr, objName) => {
  const data = arr.reduce((acc, { aggData }) => {
    if (aggData) {
      Object.entries(aggData).forEach(([key, value]) => (acc[key] = (acc[key] || 0) + value))
    }
    return acc
  }, {})
  // console.log('ppppppppppppppppp', data)

  const dataArr = []
  for (const x in data) {
    const obj = {}
    obj[x] = data[x]
    dataArr.push(obj)
  }

  // console.log('tttttttttttttt', dataArr)

  const finalArr = []
  for (let i = 0; i < dataArr.length; i++) {
    if (i === 0 || i % 3 === 0) {
      let name = _.keys(dataArr[i])[0].substring(2, 4) + '-' + _.keys(dataArr[i])[0].substring(4, 6)
      let total = Object.values(dataArr[i])[0]
      let pos = Object.values(dataArr[i + 1])[0]
      let neg = Object.values(dataArr[i + 2])[0]
      if (total !== 0) {
        let PXI = Math.round(((pos - neg) / total) * 100)
        if (objName === 'Mention Rate') {
          let Mentionrate = Math.round(((pos + neg) / total) * 100)
          let obj = { name }
          obj['Mention Rate'] = Mentionrate
          finalArr.push(obj)
        } else if (objName === 'PXI') finalArr.push({ name, PXI })
      }
    }
  }

  return finalArr
}
export const formatQuarterlyAggData2 = (arr, objName) => {
  const aggArr = []
  arr.reduce((res, value) => {
    let year_quarter = `${value._id.year.toString().substring(2, 4)}-Q${value._id.quarter}`
    // let year_quarter = value._id.year_value
    if (!Object.prototype.hasOwnProperty.call(res, year_quarter)) {
      res[year_quarter] = {
        name: year_quarter,
        pos_total: 0,
        neg_total: 0,
        topic_total: 0,
        review_total: 0,
        unfiltered_total: 0,
        // PXI: 0,
        // mention_rate: 0,
      }
      aggArr.push(res[year_quarter])
    }
    res[year_quarter].pos_total += value.pos_total
    res[year_quarter].neg_total += value.neg_total
    res[year_quarter].topic_total += value.topic_total
    res[year_quarter].review_total += value.review_total
    res[year_quarter].unfiltered_total += value.unfiltered_total
    // if (res[year_quarter].review_total !== 0) {
    //   let PXI = Math.round(((res[year_quarter].pos_total - res[year_quarter].neg_total) / res[year_quarter].review_total) * 100)
    //   res[year_quarter].PXI = PXI
    //   if (res[year_quarter].unfiltered_total > 0) {
    //     let mention_rate = Math.round((res[year_quarter].review_total / res[year_quarter].unfiltered_total) * 100)
    //     res[year_quarter].mention_rate = mention_rate
    //   }
    // }
    return res
  }, {})

  const finalArr = []
  for (let i = 0; i < aggArr.length; i++) {
    let { name, pos_total, neg_total, topic_total, review_total, unfiltered_total } = aggArr[i]
    if (review_total !== 0) {
      if (objName === 'Mention Rate') {
        if (unfiltered_total > 0) {
          let mentionRate = Math.round((topic_total / unfiltered_total) * 100)
          finalArr.push({ name: name.toString(), 'Competitive Set': mentionRate })
        }
      } else if (objName === 'PXI') {
        let PXI = Math.round(((pos_total - neg_total) / review_total) * 100)
        finalArr.push({ name: name.toString(), 'Competitive Set': PXI })
      }
    }
  }
  return finalArr
}
export const formatYearlyAggData = (arr, objName) => {
  const sumTrendsData = (arr) => {
    return arr.reduce((acc, { aggData }) => {
      if (aggData) {
        Object.entries(aggData).forEach(([key, value]) => (acc[key] = (acc[key] || 0) + value))
      }
      return acc
    }, {})
  }

  let data
  const sample = arr[0]
  if (_.keys(sample).includes('_id')) {
    data = sumTrendsData(arr)
  } else data = arr

  const dataArr = []
  for (const x in data) {
    const obj = {}
    obj[x] = data[x]
    dataArr.push(obj)
  }

  //grouping data by year
  const findCurrentYears = () => {
    const date = new Date()
    const year = date.getFullYear()
    let startYr = year - 4
    const yearArr = []
    while (startYr <= year) {
      yearArr.push(startYr.toString())
      startYr++
    }
    return yearArr
  }
  const yearArr = findCurrentYears()
  const yrArr1 = []
  const yrArr2 = []
  const yrArr3 = []
  const yrArr4 = []
  const yrArr5 = []

  let years = []

  dataArr.map((data) => {
    let dataYr = Object.keys(data)[0].substring(0, 4)
    if (!years.includes(dataYr)) years.push(dataYr)
  })

  for (let i = 0; i < yearArr.length; i++) {
    dataArr.map((data) => {
      let dataYr = Object.keys(data)[0].substring(0, 4)
      if (i + 1 === 1 && dataYr === yearArr[i]) {
        //add into yrArr1
        yrArr1.push(data)
      } else if (i + 1 === 2 && dataYr === yearArr[i]) {
        //add into yrArr2
        yrArr2.push(data)
      } else if (i + 1 === 3 && dataYr === yearArr[i]) {
        //add into yrArr3
        yrArr3.push(data)
      } else if (i + 1 === 4 && dataYr === yearArr[i]) {
        //add into yrArr4
        yrArr4.push(data)
      } else if (i + 1 === 5 && dataYr === yearArr[i]) {
        //add into yrArr5
        yrArr5.push(data)
      }
    })
  }

  const aggArr = []
  yearArr.map((year, i) => {
    let modifiedYr = year.substring(2, 4)
    let obj = {}
    if (i + 1 === 1) {
      obj[modifiedYr] = yrArr1
      aggArr.push(obj)
    } else if (i + 1 === 2) {
      obj[modifiedYr] = yrArr2
      aggArr.push(obj)
    } else if (i + 1 === 3) {
      obj[modifiedYr] = yrArr3
      aggArr.push(obj)
    } else if (i + 1 === 4) {
      obj[modifiedYr] = yrArr4
      aggArr.push(obj)
    } else if (i + 1 === 5) {
      obj[modifiedYr] = yrArr5
      aggArr.push(obj)
    }
  })

  const finalArr = []
  aggArr.map((data) => {
    let obj = {}
    for (const x in data) {
      obj.name = x
      let pos = 0
      let neg = 0
      let total = 0
      data[x].map((val) => {
        const keyName = _.keys(val)[0]
        if (keyName && keyName.includes('Total')) total += Object.values(val)[0]
        else if (keyName && keyName.includes('Pos')) pos += Object.values(val)[0]
        else if (keyName && keyName.includes('Neg')) neg += Object.values(val)[0]
      })
      if (total !== 0) {
        let PXI = Math.round(((pos - neg) / total) * 100)
        if (objName === 'Mention Rate') {
          obj['Mention Rate'] = PXI
          finalArr.push(obj)
        } else if (objName === 'PXI') {
          obj.PXI = PXI
          finalArr.push(obj)
        }
      }
    }
  })

  return finalArr
}
export const formatYearlyAggData2 = (arr, objName) => {
  const aggArr = []
  arr.reduce((res, value) => {
    let year = value._id.year.toString().substring(2, 4)
    if (!Object.prototype.hasOwnProperty.call(res, year)) {
      res[year] = {
        name: year,
        pos_total: 0,
        neg_total: 0,
        topic_total: 0,
        review_total: 0,
        unfiltered_total: 0,
      }
      aggArr.push(res[year])
    }
    res[year].pos_total += value.pos_total
    res[year].neg_total += value.neg_total
    res[year].topic_total += value.topic_total
    res[year].review_total += value.review_total
    res[year].unfiltered_total += value.unfiltered_total
    return res
  }, {})

  const finalArr = []
  for (let i = 0; i < aggArr.length; i++) {
    let { name, pos_total, neg_total, topic_total, review_total, unfiltered_total } = aggArr[i]
    let obj = {}
    obj.name = name
    if (review_total !== 0) {
      if (objName === 'Mention Rate') {
        if (unfiltered_total > 0) {
          let mentionRate = Math.round((topic_total / unfiltered_total) * 100)
          obj['Competitive Set'] = mentionRate
          finalArr.push(obj)
        }
      } else if (objName === 'PXI') {
        let PXI = Math.round(((pos_total - neg_total) / review_total) * 100)
        obj['Competitive Set'] = PXI
        finalArr.push(obj)
      }
    }
  }
  return finalArr
}

export const formatQuarterlyClassData = (arr) => {
  let unclassSum = arr.reduce((acc, { unclassData }) => {
    Object.entries(unclassData).forEach(([key, value]) => (acc[key] = (acc[key] || 0) + value))
    return acc
  }, {})

  let cuSum = arr.reduce((acc, { cuData }) => {
    Object.entries(cuData).forEach(([key, value]) => (acc[key] = (acc[key] || 0) + value))
    return acc
  }, {})

  let bankSum = arr.reduce((acc, { bankData }) => {
    Object.entries(bankData).forEach(([key, value]) => (acc[key] = (acc[key] || 0) + value))
    return acc
  }, {})

  let digSum = arr.reduce((acc, { digData }) => {
    Object.entries(digData).forEach(([key, value]) => (acc[key] = (acc[key] || 0) + value))
    return acc
  }, {})

  const unclassArr = []
  for (const x in unclassSum) {
    const obj = {}
    obj[x] = unclassSum[x]
    unclassArr.push(obj)
  }

  const cuArr = []
  for (const x in cuSum) {
    const obj = {}
    obj[x] = cuSum[x]
    cuArr.push(obj)
  }
  const bankArr = []
  for (const x in bankSum) {
    const obj = {}
    obj[x] = bankSum[x]
    bankArr.push(obj)
  }
  const digArr = []
  for (const x in digSum) {
    const obj = {}
    obj[x] = digSum[x]
    digArr.push(obj)
  }

  const mergedArr = []
  for (let i = 0; i < unclassArr.length; i++) {
    if (i === 0 || i % 3 === 0) {
      const name = _.keys(unclassArr[i])[0].substring(2, 4) + '-' + _.keys(unclassArr[i])[0].substring(4, 6)
      let total = Object.values(unclassArr[i])[0]
      let pos = Object.values(unclassArr[i + 1])[0]
      let neg = Object.values(unclassArr[i + 2])[0]
      if (total !== 0) {
        let unclassPXI = Math.round(((pos - neg) / total) * 100)
        let obj = {}
        obj.name = name
        obj.Unclassified = unclassPXI
        mergedArr.push(obj)
      }
    }
  }

  for (let i = 0; i < cuArr.length; i++) {
    if (i === 0 || i % 3 === 0) {
      const name = _.keys(cuArr[i])[0].substring(2, 4) + '-' + _.keys(cuArr[i])[0].substring(4, 6)
      let total = Object.values(cuArr[i])[0]
      let pos = Object.values(cuArr[i + 1])[0]
      let neg = Object.values(cuArr[i + 2])[0]
      if (total !== 0) {
        let cuPXI = Math.round(((pos - neg) / total) * 100)
        let obj = {}
        obj.name = name
        obj['Credit Union'] = cuPXI
        mergedArr.push(obj)
      }
    }
  }

  for (let i = 0; i < bankArr.length; i++) {
    if (i === 0 || i % 3 === 0) {
      const name = _.keys(bankArr[i])[0].substring(2, 4) + '-' + _.keys(bankArr[i])[0].substring(4, 6)
      let total = Object.values(bankArr[i])[0]
      let pos = Object.values(bankArr[i + 1])[0]
      let neg = Object.values(bankArr[i + 2])[0]
      if (total !== 0) {
        let bankPXI = Math.round(((pos - neg) / total) * 100)
        let obj = {}
        obj.name = name
        obj.Bank = bankPXI
        mergedArr.push(obj)
      }
    }
  }

  for (let i = 0; i < digArr.length; i++) {
    if (i === 0 || i % 3 === 0) {
      const name = _.keys(digArr[i])[0].substring(2, 4) + '-' + _.keys(digArr[i])[0].substring(4, 6)
      let total = Object.values(digArr[i])[0]
      let pos = Object.values(digArr[i + 1])[0]
      let neg = Object.values(digArr[i + 2])[0]
      if (total !== 0) {
        let digPXI = Math.round(((pos - neg) / total) * 100)
        let obj = {}
        obj.name = name
        obj['Digital Bank'] = digPXI
        mergedArr.push(obj)
      }
    }
  }

  var map = {}
  mergedArr.forEach(function (item) {
    var id = item.name
    if (map[id] === undefined) {
      map[id] = item
    } else {
      var existing = map[id]
      for (var propt in item) {
        existing[propt] = item[propt]
      }
    }
  })
  var results = []
  Object.keys(map).forEach((k) => results.push(map[k]))
  const finalArr = results.sort((a, b) => (a.name > b.name ? 1 : -1))
  return finalArr
}
export const formatQuarterlyClassData2 = (arr, objName) => {
  const classifications = ['Credit Union', 'Bank', 'Digital Bank', 'Unclassified']

  const aggArr = []
  arr.reduce((res, value) => {
    let year_quarter = `${value._id.year.toString().substring(2, 4)}-Q${value._id.quarter}`
    let classification = value._id.classification
    if (!Object.prototype.hasOwnProperty.call(res, year_quarter)) {
      res[year_quarter] = {
        name: year_quarter,
        'Credit Union': {
          pos_total: 0,
          neg_total: 0,
          topic_total: 0,
          review_total: 0,
          unfiltered_total: 0,
        },
        Bank: {
          pos_total: 0,
          neg_total: 0,
          topic_total: 0,
          review_total: 0,
          unfiltered_total: 0,
        },
        'Digital Bank': {
          pos_total: 0,
          neg_total: 0,
          topic_total: 0,
          review_total: 0,
          unfiltered_total: 0,
        },
        Unclassified: {
          pos_total: 0,
          neg_total: 0,
          topic_total: 0,
          review_total: 0,
          unfiltered_total: 0,
        },
      }
      aggArr.push(res[year_quarter])
    }
    res[year_quarter][classification].pos_total += value.pos_total
    res[year_quarter][classification].neg_total += value.neg_total
    res[year_quarter][classification].topic_total += value.topic_total
    res[year_quarter][classification].review_total += value.review_total
    res[year_quarter][classification].unfiltered_total += value.unfiltered_total
    return res
  }, {})

  const finalArr = []
  for (let i = 0; i < aggArr.length; i++) {
    let aggItem = aggArr[i]
    let obj = { name: aggItem.name }
    for (const [key, value] of Object.entries(aggItem)) {
      if (classifications.includes(key)) {
        if (value.review_total !== 0) {
          if (objName === 'Mention Rate') {
            let mentionRate = Math.round((value.topic_total / value.unfiltered_total) * 100)
            obj[key] = mentionRate
          } else {
            let PXI = Math.round(((value.pos_total - value.neg_total) / value.review_total) * 100)
            obj[key] = PXI
          }
        }
      }
    }
    finalArr.push(obj)
  }
  return finalArr
}

export const formatYearlyClassData = (arr) => {
  let unclassSum = arr.reduce((acc, { unclassData }) => {
    Object.entries(unclassData).forEach(([key, value]) => (acc[key] = (acc[key] || 0) + value))
    return acc
  }, {})

  let cuSum = arr.reduce((acc, { cuData }) => {
    Object.entries(cuData).forEach(([key, value]) => (acc[key] = (acc[key] || 0) + value))
    return acc
  }, {})

  let bankSum = arr.reduce((acc, { bankData }) => {
    Object.entries(bankData).forEach(([key, value]) => (acc[key] = (acc[key] || 0) + value))
    return acc
  }, {})

  let digSum = arr.reduce((acc, { digData }) => {
    Object.entries(digData).forEach(([key, value]) => (acc[key] = (acc[key] || 0) + value))
    return acc
  }, {})

  const unclassArr = []
  for (const x in unclassSum) {
    const obj = {}
    obj[x] = unclassSum[x]
    unclassArr.push(obj)
  }

  const cuArr = []
  for (const x in cuSum) {
    const obj = {}
    obj[x] = cuSum[x]
    cuArr.push(obj)
  }
  const bankArr = []
  for (const x in bankSum) {
    const obj = {}
    obj[x] = bankSum[x]
    bankArr.push(obj)
  }
  const digArr = []
  for (const x in digSum) {
    const obj = {}
    obj[x] = digSum[x]
    digArr.push(obj)
  }

  const findCurrentYears = () => {
    const date = new Date()
    const year = date.getFullYear()
    let startYr = year - 4
    const yearArr = []
    while (startYr <= year) {
      yearArr.push(startYr.toString())
      startYr++
    }
    return yearArr
  }
  const yearArr = findCurrentYears()
  const unclassYrArr1 = []
  const unclassYrArr2 = []
  const unclassYrArr3 = []
  const unclassYrArr4 = []
  const unclassYrArr5 = []

  for (let i = 0; i < yearArr.length; i++) {
    unclassArr.map((unclass) => {
      let unclassYr = Object.keys(unclass)[0].substring(0, 4)
      if (i + 1 === 1 && unclassYr === yearArr[i]) {
        unclassYrArr1.push(unclass)
      } else if (i + 1 === 2 && unclassYr === yearArr[i]) {
        unclassYrArr2.push(unclass)
      } else if (i + 1 === 3 && unclassYr === yearArr[i]) {
        unclassYrArr3.push(unclass)
      } else if (i + 1 === 4 && unclassYr === yearArr[i]) {
        unclassYrArr4.push(unclass)
      } else if (i + 1 === 5 && unclassYr === yearArr[i]) {
        unclassYrArr5.push(unclass)
      }
    })
  }

  const unclassAggArr = []
  yearArr.map((year, i) => {
    let modifiedYr = year.substring(2, 4)
    let obj = {}
    if (i + 1 === 1) {
      obj[modifiedYr] = unclassYrArr1
      unclassAggArr.push(obj)
    } else if (i + 1 === 2) {
      obj[modifiedYr] = unclassYrArr2
      unclassAggArr.push(obj)
    } else if (i + 1 === 3) {
      obj[modifiedYr] = unclassYrArr3
      unclassAggArr.push(obj)
    } else if (i + 1 === 4) {
      obj[modifiedYr] = unclassYrArr4
      unclassAggArr.push(obj)
    } else if (i + 1 === 5) {
      obj[modifiedYr] = unclassYrArr5
      unclassAggArr.push(obj)
    }
  })

  const cuYrArr1 = []
  const cuYrArr2 = []
  const cuYrArr3 = []
  const cuYrArr4 = []
  const cuYrArr5 = []

  for (let i = 0; i < yearArr.length; i++) {
    cuArr.map((cu) => {
      let cuYr = Object.keys(cu)[0].substring(0, 4)
      if (i + 1 === 1 && cuYr === yearArr[i]) {
        cuYrArr1.push(cu)
      } else if (i + 1 === 2 && cuYr === yearArr[i]) {
        cuYrArr2.push(cu)
      } else if (i + 1 === 3 && cuYr === yearArr[i]) {
        cuYrArr3.push(cu)
      } else if (i + 1 === 4 && cuYr === yearArr[i]) {
        cuYrArr4.push(cu)
      } else if (i + 1 === 5 && cuYr === yearArr[i]) {
        cuYrArr5.push(cu)
      }
    })
  }

  const cuAggArr = []
  yearArr.map((year, i) => {
    let modifiedYr = year.substring(2, 4)
    let obj = {}
    if (i + 1 === 1) {
      obj[modifiedYr] = cuYrArr1
      cuAggArr.push(obj)
    } else if (i + 1 === 2) {
      obj[modifiedYr] = cuYrArr2
      cuAggArr.push(obj)
    } else if (i + 1 === 3) {
      obj[modifiedYr] = cuYrArr3
      cuAggArr.push(obj)
    } else if (i + 1 === 4) {
      obj[modifiedYr] = cuYrArr4
      cuAggArr.push(obj)
    } else if (i + 1 === 5) {
      obj[modifiedYr] = cuYrArr5
      cuAggArr.push(obj)
    }
  })

  const bankYrArr1 = []
  const bankYrArr2 = []
  const bankYrArr3 = []
  const bankYrArr4 = []
  const bankYrArr5 = []

  for (let i = 0; i < yearArr.length; i++) {
    bankArr.map((bank) => {
      let bankYr = Object.keys(bank)[0].substring(0, 4)
      if (i + 1 === 1 && bankYr === yearArr[i]) {
        bankYrArr1.push(bank)
      } else if (i + 1 === 2 && bankYr === yearArr[i]) {
        bankYrArr2.push(bank)
      } else if (i + 1 === 3 && bankYr === yearArr[i]) {
        bankYrArr3.push(bank)
      } else if (i + 1 === 4 && bankYr === yearArr[i]) {
        bankYrArr4.push(bank)
      } else if (i + 1 === 5 && bankYr === yearArr[i]) {
        bankYrArr5.push(bank)
      }
    })
  }

  const bankAggArr = []
  yearArr.map((year, i) => {
    let modifiedYr = year.substring(2, 4)
    let obj = {}
    if (i + 1 === 1) {
      obj[modifiedYr] = bankYrArr1
      bankAggArr.push(obj)
    } else if (i + 1 === 2) {
      obj[modifiedYr] = bankYrArr2
      bankAggArr.push(obj)
    } else if (i + 1 === 3) {
      obj[modifiedYr] = bankYrArr3
      bankAggArr.push(obj)
    } else if (i + 1 === 4) {
      obj[modifiedYr] = bankYrArr4
      bankAggArr.push(obj)
    } else if (i + 1 === 5) {
      obj[modifiedYr] = bankYrArr5
      bankAggArr.push(obj)
    }
  })

  const digYrArr1 = []
  const digYrArr2 = []
  const digYrArr3 = []
  const digYrArr4 = []
  const digYrArr5 = []

  for (let i = 0; i < yearArr.length; i++) {
    digArr.map((dig) => {
      let digYr = Object.keys(dig)[0].substring(0, 4)
      if (i + 1 === 1 && digYr === yearArr[i]) {
        digYrArr1.push(dig)
      } else if (i + 1 === 2 && digYr === yearArr[i]) {
        digYrArr2.push(dig)
      } else if (i + 1 === 3 && digYr === yearArr[i]) {
        digYrArr3.push(dig)
      } else if (i + 1 === 4 && digYr === yearArr[i]) {
        digYrArr4.push(dig)
      } else if (i + 1 === 5 && digYr === yearArr[i]) {
        digYrArr5.push(dig)
      }
    })
  }

  const digAggArr = []
  yearArr.map((year, i) => {
    let modifiedYr = year.substring(2, 4)
    let obj = {}
    if (i + 1 === 1) {
      obj[modifiedYr] = digYrArr1
      digAggArr.push(obj)
    } else if (i + 1 === 2) {
      obj[modifiedYr] = digYrArr2
      digAggArr.push(obj)
    } else if (i + 1 === 3) {
      obj[modifiedYr] = digYrArr3
      digAggArr.push(obj)
    } else if (i + 1 === 4) {
      obj[modifiedYr] = digYrArr4
      digAggArr.push(obj)
    } else if (i + 1 === 5) {
      obj[modifiedYr] = digYrArr5
      digAggArr.push(obj)
    }
  })

  const mergedArr = []
  unclassAggArr.map((data) => {
    let obj = {}
    for (const x in data) {
      obj.name = x
      let pos = 0
      let neg = 0
      let total = 0
      data[x].map((val) => {
        const keyName = _.keys(val)[0]
        if (keyName.includes('Total')) total += Object.values(val)[0]
        else if (keyName.includes('Pos')) pos += Object.values(val)[0]
        else if (keyName.includes('Neg')) neg += Object.values(val)[0]
      })
      if (total !== 0) {
        let PXI = Math.round(((pos - neg) / total) * 100)
        obj['Unclassified'] = PXI
        mergedArr.push(obj)
      }
    }
  })

  cuAggArr.map((data) => {
    let obj = {}
    for (const x in data) {
      obj.name = x
      let pos = 0
      let neg = 0
      let total = 0
      data[x].map((val) => {
        const keyName = _.keys(val)[0]
        if (keyName.includes('Total')) total += Object.values(val)[0]
        else if (keyName.includes('Pos')) pos += Object.values(val)[0]
        else if (keyName.includes('Neg')) neg += Object.values(val)[0]
      })
      if (total !== 0) {
        let PXI = Math.round(((pos - neg) / total) * 100)
        obj['Credit Union'] = PXI
        mergedArr.push(obj)
      }
    }
  })

  bankAggArr.map((data) => {
    let obj = {}
    for (const x in data) {
      obj.name = x
      let pos = 0
      let neg = 0
      let total = 0
      data[x].map((val) => {
        const keyName = _.keys(val)[0]
        if (keyName.includes('Total')) total += Object.values(val)[0]
        else if (keyName.includes('Pos')) pos += Object.values(val)[0]
        else if (keyName.includes('Neg')) neg += Object.values(val)[0]
      })
      if (total !== 0) {
        let PXI = Math.round(((pos - neg) / total) * 100)
        obj['Bank'] = PXI
        mergedArr.push(obj)
      }
    }
  })

  digAggArr.map((data) => {
    let obj = {}
    for (const x in data) {
      obj.name = x
      let pos = 0
      let neg = 0
      let total = 0
      data[x].map((val) => {
        const keyName = _.keys(val)[0]
        if (keyName.includes('Total')) total += Object.values(val)[0]
        else if (keyName.includes('Pos')) pos += Object.values(val)[0]
        else if (keyName.includes('Neg')) neg += Object.values(val)[0]
      })
      if (total !== 0) {
        let PXI = Math.round(((pos - neg) / total) * 100)
        obj['Digital Bank'] = PXI
        mergedArr.push(obj)
      }
    }
  })

  var map = {}
  mergedArr.forEach(function (item) {
    var id = item.name
    if (map[id] === undefined) {
      map[id] = item
    } else {
      var existing = map[id]
      for (var propt in item) {
        existing[propt] = item[propt]
      }
    }
  })
  var results = []
  Object.keys(map).forEach((k) => results.push(map[k]))
  const finalArr = results.sort((a, b) => (a.name > b.name ? 1 : -1))
  return finalArr
}
export const formatYearlyClassData2 = (arr, objName) => {
  const classifications = ['Credit Union', 'Bank', 'Digital Bank', 'Unclassified']
  const aggArr = []
  arr.reduce((res, value) => {
    let year = `${value._id.year.toString().substring(2, 4)}`
    let classification = value._id.classification
    if (!Object.prototype.hasOwnProperty.call(res, year)) {
      res[year] = {
        name: year,
        'Credit Union': {
          pos_total: 0,
          neg_total: 0,
          topic_total: 0,
          review_total: 0,
          unfiltered_total: 0,
        },
        Bank: {
          pos_total: 0,
          neg_total: 0,
          topic_total: 0,
          review_total: 0,
          unfiltered_total: 0,
        },
        'Digital Bank': {
          pos_total: 0,
          neg_total: 0,
          topic_total: 0,
          review_total: 0,
          unfiltered_total: 0,
        },
        Unclassified: {
          pos_total: 0,
          neg_total: 0,
          topic_total: 0,
          review_total: 0,
          unfiltered_total: 0,
        },
      }
      aggArr.push(res[year])
    }
    res[year][classification].pos_total += value.pos_total
    res[year][classification].neg_total += value.neg_total
    res[year][classification].topic_total += value.topic_total
    res[year][classification].review_total += value.review_total
    res[year][classification].unfiltered_total += value.unfiltered_total
    return res
  }, {})

  const finalArr = []
  for (let i = 0; i < aggArr.length; i++) {
    let aggItem = aggArr[i]
    let obj = { name: aggItem.name }
    for (const [key, value] of Object.entries(aggItem)) {
      if (classifications.includes(key)) {
        if (value.review_total !== 0) {
          if (objName === 'Mention Rate') {
            let mentionRate = Math.round((value.topic_total / value.unfiltered_total) * 100)
            obj[key] = mentionRate
          } else {
            let PXI = Math.round(((value.pos_total - value.neg_total) / value.review_total) * 100)
            obj[key] = PXI
          }
        }
      }
    }
    finalArr.push(obj)
  }
  return finalArr
}

export const formatQuarterlyFIData = (arr) => {
  const mergedArr = []
  arr.map((fiObj) => {
    const { aggData } = fiObj
    const dataArr = []
    for (const x in aggData) {
      let obj = {}
      obj[x] = aggData[x]
      dataArr.push(obj)
    }
    for (let i = 0; i < dataArr.length; i++) {
      if (i === 0 || i % 3 === 0) {
        let total = Object.values(dataArr[i])[0]
        let pos = Object.values(dataArr[i + 1])[0]
        let neg = Object.values(dataArr[i + 2])[0]
        if (total !== 0) {
          const name = _.keys(dataArr[i])[0].substring(2, 4) + '-' + _.keys(dataArr[i])[0].substring(4, 6)
          let PXI = Math.round(((pos - neg) / total) * 100)
          let obj = {}
          obj[fiObj._id] = PXI
          obj.name = name
          mergedArr.push(obj)
        }
      }
    }
  })
  var map = {}
  mergedArr.forEach(function (item) {
    var id = item.name
    if (map[id] === undefined) {
      map[id] = item
    } else {
      var existing = map[id]
      for (var propt in item) {
        existing[propt] = item[propt]
      }
    }
  })
  var results = []
  Object.keys(map).forEach((k) => results.push(map[k]))
  const finalArr = results.sort((a, b) => (a.name > b.name ? 1 : -1))

  return finalArr
}
export const formatQuarterlyFIData2 = (arr, objName) => {
  const aggArr = []
  arr.reduce((res, value) => {
    let year_quarter = `${value._id.year.toString().substring(2, 4)}-Q${value._id.quarter}`
    // let year_quarter = value._id.year_value
    let fi_id = value._id.fi_id
    if (!Object.prototype.hasOwnProperty.call(res, year_quarter)) {
      res[year_quarter] = {
        name: year_quarter,
      }
      aggArr.push(res[year_quarter])
    }
    if (!res[year_quarter][fi_id]) {
      res[year_quarter][fi_id] = {
        pos_total: 0,
        neg_total: 0,
        topic_total: 0,
        review_total: 0,
        unfiltered_total: 0,
      }
    }
    res[year_quarter][fi_id].pos_total += value.pos_total
    res[year_quarter][fi_id].neg_total += value.neg_total
    res[year_quarter][fi_id].topic_total += value.topic_total
    res[year_quarter][fi_id].review_total += value.review_total
    res[year_quarter][fi_id].unfiltered_total += value.unfiltered_total
    return res
  }, {})

  const finalArr = []
  for (let i = 0; i < aggArr.length; i++) {
    let aggItem = aggArr[i]
    let obj = { name: aggItem.name }
    for (const [key, value] of Object.entries(aggItem)) {
      if (key !== 'name') {
        if (value.review_total !== 0) {
          if (objName === 'Mention Rate') {
            let mentionRate = Math.round((value.topic_total / value.unfiltered_total) * 100)
            obj[key] = mentionRate
          } else {
            let PXI = Math.round(((value.pos_total - value.neg_total) / value.review_total) * 100)
            obj[key] = PXI
          }
        }
      }
    }
    finalArr.push(obj)
  }
  return finalArr
}

export const formatYearlyFIData = (arr) => {
  const mergedArr = []
  arr.map((fiObj) => {
    const { aggData, _id } = fiObj
    const dataArr = []
    for (const x in aggData) {
      let obj = {}
      obj[x] = aggData[x]
      dataArr.push(obj)
    }

    const findCurrentYears = () => {
      const date = new Date()
      const year = date.getFullYear()
      let startYr = year - 4
      const yearArr = []
      while (startYr <= year) {
        yearArr.push(startYr.toString())
        startYr++
      }
      return yearArr
    }
    const yearArr = findCurrentYears()
    const yrArr1 = []
    const yrArr2 = []
    const yrArr3 = []
    const yrArr4 = []
    const yrArr5 = []

    for (let i = 0; i < yearArr.length; i++) {
      dataArr.map((data) => {
        let dataYr = Object.keys(data)[0].substring(0, 4)
        if (i + 1 === 1 && dataYr === yearArr[i]) {
          yrArr1.push(data)
        } else if (i + 1 === 2 && dataYr === yearArr[i]) {
          yrArr2.push(data)
        } else if (i + 1 === 3 && dataYr === yearArr[i]) {
          yrArr3.push(data)
        } else if (i + 1 === 4 && dataYr === yearArr[i]) {
          yrArr4.push(data)
        } else if (i + 1 === 5 && dataYr === yearArr[i]) {
          yrArr5.push(data)
        }
      })
    }

    yearArr.map((year, i) => {
      let modifiedYr = year.substring(2, 4)
      let obj = {}
      if (i + 1 === 1) {
        obj[modifiedYr] = yrArr1
        obj.name = _id
        mergedArr.push(obj)
      } else if (i + 1 === 2) {
        obj[modifiedYr] = yrArr2
        obj.name = _id
        mergedArr.push(obj)
      } else if (i + 1 === 3) {
        obj[modifiedYr] = yrArr3
        obj.name = _id
        mergedArr.push(obj)
      } else if (i + 1 === 4) {
        obj[modifiedYr] = yrArr4
        obj.name = _id
        mergedArr.push(obj)
      } else if (i + 1 === 5) {
        obj[modifiedYr] = yrArr5
        obj.name = _id
        mergedArr.push(obj)
      }
    })
  })

  const pxiArr = []
  mergedArr.map((data) => {
    let obj = {}
    let fi
    let name
    let pxi
    let pos = 0
    let neg = 0
    let total = 0
    for (const x in data) {
      if (x.length === 2) {
        data[x].map((val) => {
          name = x
          const keyName = _.keys(val)[0]
          if (keyName.includes('Total')) total += Object.values(val)[0]
          else if (keyName.includes('Pos')) pos += Object.values(val)[0]
          else if (keyName.includes('Neg')) neg += Object.values(val)[0]
        })
      } else {
        fi = data[x]
      }
    }
    if (total !== 0) {
      pxi = Math.round(((pos - neg) / total) * 100)
      obj[fi] = pxi
      obj.name = name
      pxiArr.push(obj)
    }
  })

  var map = {}
  pxiArr.forEach(function (item) {
    var id = item.name
    if (map[id] === undefined) {
      map[id] = item
    } else {
      var existing = map[id]
      for (var propt in item) {
        existing[propt] = item[propt]
      }
    }
  })
  var results = []
  Object.keys(map).forEach((k) => results.push(map[k]))
  const finalArr = results.sort((a, b) => (a.name > b.name ? 1 : -1))
  return finalArr
}
export const formatYearlyFIData2 = (arr, objName) => {
  const aggArr = []
  arr.reduce((res, value) => {
    let year = `${value._id.year.toString().substring(2, 4)}`
    let fi_id = value._id.fi_id
    if (!Object.prototype.hasOwnProperty.call(res, year)) {
      res[year] = {
        name: year,
      }
      aggArr.push(res[year])
    }
    if (!res[year][fi_id]) {
      res[year][fi_id] = {
        pos_total: 0,
        neg_total: 0,
        topic_total: 0,
        review_total: 0,
        unfiltered_total: 0,
      }
    }
    res[year][fi_id].pos_total += value.pos_total
    res[year][fi_id].neg_total += value.neg_total
    res[year][fi_id].topic_total += value.topic_total
    res[year][fi_id].review_total += value.review_total
    res[year][fi_id].unfiltered_total += value.unfiltered_total
    return res
  }, {})

  const finalArr = []
  for (let i = 0; i < aggArr.length; i++) {
    let aggItem = aggArr[i]
    let obj = { name: aggItem.name }
    for (const [key, value] of Object.entries(aggItem)) {
      if (key !== 'name') {
        if (value.review_total !== 0) {
          if (objName === 'Mention Rate') {
            let mentionRate = Math.round((value.topic_total / value.unfiltered_total) * 100)
            obj[key] = mentionRate
          } else {
            let PXI = Math.round(((value.pos_total - value.neg_total) / value.review_total) * 100)
            obj[key] = PXI
          }
        }
      }
    }
    finalArr.push(obj)
  }
  return finalArr
}

export const createLineArr = (arr) => {
  const fiArr = []
  arr.map(({ _id }) => {
    if (!fiArr.includes(_id)) {
      fiArr.push(_id)
    }
  })
  return fiArr
}

/* TRENDING */
export const formatTrendingColumns = (arr) => {
  // console.log('pppppppppppppppppppppppppp', arr)
  let financialBankCount = arr.length
  const serv = []
  const brand = []
  const fees = []
  const access = []
  const prod = []
  const des = []
  arr.map((el) => {
    for (const x in el) {
      if (x.includes('serv')) {
        let obj = {}
        obj[x] = el[x]
        serv.push(obj)
      }
      if (x.includes('acc')) {
        let obj = {}
        obj[x] = el[x]
        access.push(obj)
      }
      if (x.includes('brand')) {
        let obj = {}
        obj[x] = el[x]
        brand.push(obj)
      }
      if (x.includes('fees')) {
        let obj = {}
        obj[x] = el[x]
        fees.push(obj)
      }
      if (x.includes('prod')) {
        let obj = {}
        obj[x] = el[x]
        prod.push(obj)
      }
      if (x.includes('des')) {
        let obj = {}
        obj[x] = el[x]
        des.push(obj)
      }
    }
  })

  const sumTotals = (arr) => {
    return arr.reduce((acc, arr) => {
      Object.entries(arr).forEach(([key, value]) => (acc[key] = (acc[key] || 0) + value))
      return acc
    })
  }

  const summedServ = !_.isEmpty(serv) ? sumTotals(serv) : null
  const summedBrand = !_.isEmpty(brand) ? sumTotals(brand) : null
  const summedFees = !_.isEmpty(fees) ? sumTotals(fees) : null
  const summedAcc = !_.isEmpty(access) ? sumTotals(access) : null
  const summedProd = !_.isEmpty(prod) ? sumTotals(prod) : null
  const summedDes = !_.isEmpty(des) ? sumTotals(des) : null

  const findAvg = (obj, arr, parentObj) => {
    for (const x in obj) {
      let avg = Math.round(obj[x] / (arr.length / 2))
      parentObj[`avg${x[0].toUpperCase()}${x.substring(1)}`] = avg
    }
  }

  let avgObj = {}
  if (summedServ) findAvg(summedServ, serv, avgObj)
  if (summedAcc) findAvg(summedAcc, access, avgObj)
  if (summedBrand) findAvg(summedBrand, brand, avgObj)
  if (summedProd) findAvg(summedProd, prod, avgObj)
  if (summedFees) findAvg(summedFees, fees, avgObj)
  if (summedDes) findAvg(summedDes, fees, avgObj)

  const totalsArr = []
  const varArr = []
  let varObj = {}
  const { avgServPXI, avgServMention, avgBrandPXI, avgBrandMention, avgAccPXI, avgAccMention, avgFeesPXI, avgFeesMention, avgProdPXI, avgProdMention, avgDesPXI, avgDesMention } = avgObj
  arr.map((fi) => {
    const { _id, servPXI, servMention, brandPXI, brandMention, accPXI, accMention, feesPXI, feesMention, prodPXI, prodMention, desPXI, desMention } = fi
    if (_id.length < 500) {
      let obj = {
        _id,
      }
      if (servPXI) {
        obj.servPXI = servPXI
        obj.avgServPXI = avgServPXI
        obj.servPXIVar = servPXI - avgServPXI
        obj.servMention = servMention
        obj.avgServMention = avgServMention
        obj.servMentionVar = servMention - avgServMention
        varObj[`${_id}-ServMentionVar`] = servMention - avgServMention
      }
      if (brandPXI) {
        obj.brandPXI = brandPXI
        obj.avgBrandPXI = avgBrandPXI
        obj.brandPXIVar = brandPXI - avgBrandPXI
        obj.brandMention = brandMention
        obj.avgBrandMention = avgBrandMention
        obj.brandMentionVar = brandMention - avgBrandMention
        varObj[`${_id}-BrandMentionVar`] = brandMention - avgBrandMention
      }
      if (accPXI) {
        obj.accPXI = accPXI
        obj.avgAccPXI = avgAccPXI
        obj.accPXIVar = accPXI - avgAccPXI
        obj.accMention = accMention
        obj.avgAccMention = avgAccMention
        obj.accMentionVar = accMention - avgAccMention
        varObj[`${_id}-AccMentionVar`] = accMention - avgAccMention
      }
      if (feesPXI) {
        obj.feesPXI = feesPXI
        obj.avgFeesPXI = avgFeesPXI
        obj.feesPXIVar = feesPXI - avgFeesPXI
        obj.feesMention = feesMention
        obj.avgFeesMention = avgFeesMention
        obj.feesMentionVar = feesMention - avgFeesMention
        varObj[`${_id}-FeesMentionVar`] = feesMention - avgFeesMention
      }
      if (prodPXI) {
        obj.prodPXI = prodPXI
        obj.avgProdPXI = avgProdPXI
        obj.prodPXIVar = prodPXI - avgProdPXI
        obj.prodMention = prodMention
        obj.avgProdMention = avgProdMention
        obj.prodMentionVar = prodMention - avgProdMention
        varObj[`${_id}-ProdMentionVar`] = prodMention - avgProdMention
      }
      if (desPXI) {
        obj.desPXI = desPXI
        obj.avgDesPXI = avgDesPXI
        obj.desPXIVar = desPXI - avgDesPXI
        obj.desMention = desMention
        obj.avgDesMention = avgDesMention
        obj.desMentionVar = desMention - avgDesMention
        varObj[`${_id}-DesMentionVar`] = desMention - avgDesMention
      }

      totalsArr.push(obj)
    }
  })

  for (const x in varObj) {
    varArr.push([x, varObj[x]])
  }
  varArr.sort((a, b) => Math.abs(a[1]) - Math.abs(b[1]))
  const sortedArr = []
  for (let i = varArr.length - 1; i > varArr.length - 11; i--) {
    if (varArr[i]) {
      sortedArr.push(varArr[i])
    }
  }

  const mergedArr = []
  totalsArr.map((total) => {
    const { _id } = total
    sortedArr.map((sorted) => {
      const fi = sorted[0].substring(0, sorted[0].indexOf('-'))
      if (_id === fi) {
        let obj = {
          ...total,
        }
        obj[sorted[0]] = sorted[1]
        mergedArr.push(obj)
      }
    })
  })

  const columnsArr = []
  // console.log('mergedArr', mergedArr)
  mergedArr.map((merge) => {
    const { _id } = merge
    const keys = Object.keys(merge)
    const lastKey = keys[keys.length - 1]
    const variance = lastKey.substring(lastKey.indexOf('-') + 1, lastKey.length - 10)
    let mention
    let avgMention
    let pxi
    let avgPXI
    let topic
    for (const x in merge) {
      if (x.includes(variance) || x.includes(`${variance.charAt(0).toLowerCase()}${variance.substring(1)}`)) {
        if (x.includes('PXI')) {
          if (x.includes('Var')) avgPXI = merge[x]
          else if (!x.includes('avg')) pxi = merge[x]
        } else if (x.includes('Mention')) {
          if (x.includes('Var') && !x.includes('-')) avgMention = merge[x]
          else if (!x.includes('Var') && !x.includes('avg')) mention = merge[x]
        }
      }
    }

    switch (variance) {
      case 'Serv':
        topic = 'Customer Service'
        break
      case 'Prod':
        topic = 'Product Offerings'
        break
      case 'Fees':
        topic = 'Fees & Incentives'
        break
      case 'Brand':
        topic = 'Brand Equity'
        break
      case 'Acc':
        topic = 'Accessibility'
        break
      case 'Des':
        topic = 'Design And Functionality'
        break
    }
    let obj
    if (financialBankCount > 1) {
      // console.log('multiple')
      obj = {
        _id,
        topic,
        mention,
        avgMention,
        pxi,
        avgPXI,
      }
    } else {
      // console.log('single')
      avgMention = mention
      avgPXI = pxi
      avgMention = mention
      avgPXI = pxi
      obj = {
        _id,
        topic,
        mention,
        avgMention,
        pxi,
        avgPXI,
      }
    }

    columnsArr.push(obj)
  })
  // console.log('columnsArr', columnsArr)

  const finalArr = []
  columnsArr.sort((a, b) => Math.abs(a.avgMention) - Math.abs(b.avgMention))
  columnsArr.map((column, i) => {
    let obj = {
      ...column,
      rank: i + 1,
      key: i,
    }
    finalArr.push(obj)
  })
  // console.log('rrrrrrrrrrrrrrrrrrrrrrr', finalArr)
  return finalArr
}

export const formatTrendingColumns2 = (arr, avgs) => {
  let financialBankCount = arr.length
  const serv = []
  const brand = []
  const fees = []
  const access = []
  const prod = []
  const des = []
  arr.map((el) => {
    for (const x in el) {
      if (x.includes('serv')) {
        let obj = {}
        obj[x] = el[x]
        serv.push(obj)
      }
      if (x.includes('acc')) {
        let obj = {}
        obj[x] = el[x]
        access.push(obj)
      }
      if (x.includes('brand')) {
        let obj = {}
        obj[x] = el[x]
        brand.push(obj)
      }
      if (x.includes('fees')) {
        let obj = {}
        obj[x] = el[x]
        fees.push(obj)
      }
      if (x.includes('prod')) {
        let obj = {}
        obj[x] = el[x]
        prod.push(obj)
      }
      if (x.includes('des')) {
        let obj = {}
        obj[x] = el[x]
        des.push(obj)
      }
    }
  })

  const totalsArr = []
  const varArr = []
  let varObj = {}
  const {
    servPXIAvg = 0,
    servMentionAvg = 0,
    brandPXIAvg = 0,
    brandMentionAvg = 0,
    accPXIAvg = 0,
    accMentionAvg = 0,
    feesPXIAvg = 0,
    feesMentionAvg = 0,
    prodPXIAvg = 0,
    prodMentionAvg = 0,
    desPXIAvg = 0,
    desMentionAvg = 0,
  } = avgs
  arr.map((fi) => {
    const { _id, servPXI, servMention, brandPXI, brandMention, accPXI, accMention, feesPXI, feesMention, prodPXI, prodMention, desPXI, desMention } = fi
    if (_id !== 'avgs' && _id.length < 500) {
      let obj = {
        _id,
      }
      if (servPXI) {
        obj.servPXI = servPXI
        obj.servPXIAvg = servPXIAvg
        obj.servPXIVar = servPXI - servPXIAvg
        obj.servMention = servMention
        obj.servMentionAvg = servMentionAvg
        obj.servMentionVar = servMention - servMentionAvg
        varObj[`${_id}-ServMentionVar`] = servMention - servMentionAvg
      }
      if (brandPXI) {
        obj.brandPXI = brandPXI
        obj.brandPXIAvg = brandPXIAvg
        obj.brandPXIVar = brandPXI - brandPXIAvg
        obj.brandMention = brandMention
        obj.brandMentionAvg = brandMentionAvg
        obj.brandMentionVar = brandMention - brandMentionAvg
        varObj[`${_id}-BrandMentionVar`] = brandMention - brandMentionAvg
      }
      if (accPXI) {
        obj.accPXI = accPXI
        obj.accPXIAvg = accPXIAvg
        obj.accPXIVar = accPXI - accPXIAvg
        obj.accMention = accMention
        obj.accMentionAvg = accMentionAvg
        obj.accMentionVar = accMention - accMentionAvg
        varObj[`${_id}-AccMentionVar`] = accMention - accMentionAvg
      }
      if (feesPXI) {
        obj.feesPXI = feesPXI
        obj.feesPXIAvg = feesPXIAvg
        obj.feesPXIVar = feesPXI - feesPXIAvg
        obj.feesMention = feesMention
        obj.feesMentionAvg = feesMentionAvg
        obj.feesMentionVar = feesMention - feesMentionAvg
        varObj[`${_id}-FeesMentionVar`] = feesMention - feesMentionAvg
      }
      if (prodPXI) {
        obj.prodPXI = prodPXI
        obj.prodPXIAvg = prodPXIAvg
        obj.prodPXIVar = prodPXI - prodPXIAvg
        obj.prodMention = prodMention
        obj.prodMentionAvg = prodMentionAvg
        obj.prodMentionVar = prodMention - prodMentionAvg
        varObj[`${_id}-ProdMentionVar`] = prodMention - prodMentionAvg
      }
      if (desPXI) {
        obj.desPXI = desPXI
        obj.desPXIAvg = desPXIAvg
        obj.desPXIVar = desPXI - desPXIAvg
        obj.desMention = desMention
        obj.desMentionAvg = desMentionAvg
        obj.desMentionVar = desMention - desMentionAvg
        varObj[`${_id}-DesMentionVar`] = desMention - desMentionAvg
      }

      totalsArr.push(obj)
    }
  })

  for (const x in varObj) {
    varArr.push([x, varObj[x]])
  }
  varArr.sort((a, b) => Math.abs(a[1]) - Math.abs(b[1]))
  const sortedArr = []
  for (let i = varArr.length - 1; i > varArr.length - 11; i--) {
    if (varArr[i]) {
      sortedArr.push(varArr[i])
    }
  }

  const mergedArr = []
  totalsArr.map((total) => {
    const { _id } = total
    sortedArr.map((sorted) => {
      const fi = sorted[0].substring(0, sorted[0].indexOf('-'))
      if (_id === fi) {
        let obj = {
          ...total,
        }
        obj[sorted[0]] = sorted[1]
        mergedArr.push(obj)
      }
    })
  })
  // console.log('mergedArr', mergedArr)

  const columnsArr = []
  mergedArr.map((merge) => {
    const { _id } = merge
    const keys = Object.keys(merge)
    const lastKey = keys[keys.length - 1]
    const variance = lastKey.substring(lastKey.indexOf('-') + 1, lastKey.length - 10)
    let mention
    let avgMention
    let varMention
    let pxi
    let avgPXI
    let varPXI
    let topic
    // console.log(merge)
    // for (const x in merge) {
    //   if (x.includes(variance) || x.includes(`${variance.charAt(0).toLowerCase()}${variance.substring(1)}`)) {
    //     if (x.includes('PXI')) {
    //       if (x.includes('Var')) avgPXI = merge[x]
    //       else if (!x.includes('avg')) pxi = merge[x]
    //     } else if (x.includes('Mention')) {
    //       if (x.includes('Var') && !x.includes('-')) avgMention = merge[x]
    //       else if (!x.includes('Var') && !x.includes('avg')) mention = merge[x]
    //     }
    //   }
    // }
    for (const x in merge) {
      if (x.includes(variance) || x.includes(`${variance.charAt(0).toLowerCase()}${variance.substring(1)}`)) {
        if (x.includes('PXI')) {
          if (x.endsWith('PXIAvg')) avgPXI = merge[x]
          else if (!x.includes('avg') && x.endsWith('PXI')) pxi = merge[x]
          else if (x.endsWith('Var')) varPXI = merge[x]
        } else if (x.includes('Mention')) {
          if (x.endsWith('MentionAvg') && !x.includes('-')) avgMention = merge[x]
          else if (!x.includes('Var') && !x.includes('avg') && x.endsWith('Mention')) mention = merge[x]
          else if (x.endsWith('Var')) varMention = merge[x]
        }
      }
    }

    switch (variance) {
      case 'Serv':
        topic = 'Customer Service'
        break
      case 'Prod':
        topic = 'Product Offerings'
        break
      case 'Fees':
        topic = 'Fees & Incentives'
        break
      case 'Brand':
        topic = 'Brand Equity'
        break
      case 'Acc':
        topic = 'Accessibility'
        break
      case 'Des':
        topic = 'Design And Functionality'
        break
    }
    let obj
    if (financialBankCount > 1) {
      // console.log('multiple')
      obj = {
        _id,
        topic,
        mention,
        avgMention,
        varMention,
        pxi,
        avgPXI,
        varPXI,
      }
    } else {
      // console.log('single')
      avgMention = mention
      avgPXI = pxi
      avgMention = mention
      avgPXI = pxi
      obj = {
        _id,
        topic,
        mention,
        avgMention,
        varMention,
        pxi,
        avgPXI,
        varPXI,
      }
    }

    columnsArr.push(obj)
  })
  // console.log('columnsArr', columnsArr)

  const finalArr = []
  columnsArr.sort((a, b) => b.varMention - a.varMention)
  columnsArr.map((column, i) => {
    let obj = {
      ...column,
      rank: i + 1,
      key: i,
    }
    finalArr.push(obj)
  })
  return finalArr
}
