import { Button, Checkbox, Row } from 'antd'
import PropTypes from 'prop-types'

import { ToggleSwitch } from '../../components'

import './checkbox.css'

export const Checkboxes = (props) => {
  const { isSwitchVisible, apiData, isDisabled, isToggleAnd, handleAndOrChange, filterType, onChange, handleSelectAll, values, category } = props

  const renderCheckboxGroup = () => {
    return (
      <Checkbox.Group
        className="checkbox_group"
        options={apiData}
        value={values}
        disabled={isDisabled}
        onChange={(list) => {
          onChange(list, filterType, category)
        }}
      />
    )
  }

  const data = (
    <div className="title-popover">
      <p>Toggling to &quot;and&quot; will search for reviews that contain all of your choices.</p>
      <p>
        For example, if you choose Timing and Personality from Customer Service and have the toggler on &quot;and&quot; then the results will be from reviews that contain mention of both Timing and
        Personality.
      </p>
      <p>If you have the toggler set to &quot;or&quot; then your results will be from any review that mentions at least one of those topics.</p>
    </div>
  )

  return (
    <>
      <Row className="space-between">
        <div>
          <Button
            className="select-all"
            type="link"
            onClick={(e) => {
              handleSelectAll(e, filterType, apiData)
            }}
          >
            {values && values.length === apiData.length ? 'Unselect all' : 'Select all'}
          </Button>
        </div>
        {isSwitchVisible ? (
          <div>
            <ToggleSwitch
              key={`${filterType}-toggle`}
              checked={isToggleAnd}
              checkedChildren="and"
              unCheckedChildren="or"
              onChange={(value) => handleAndOrChange(value, filterType)}
              content={data}
              title=""
            />
          </div>
        ) : null}
      </Row>
      <Row>{renderCheckboxGroup()}</Row>
    </>
  )
}

Checkboxes.propTypes = {
  apiData: PropTypes.array.isRequired,
  isSwitchVisible: PropTypes.bool.isRequired,
  isToggleAnd: PropTypes.bool,
  isDisabled: PropTypes.bool,
  checked: PropTypes.bool,
  handleAndOrChange: PropTypes.func,
  onChange: PropTypes.func,
  onCheckAllChange: PropTypes.func,
  handleSelectAll: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  category: PropTypes.string,
}
