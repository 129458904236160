import PropTypes from 'prop-types'

export const HomePageLinkRow = ({ imageUrls, handleImageClick, numberRows, rowNumber, urlValues }) => {
  let rowLength = imageUrls.length / numberRows
  let rowAddend = rowLength * (rowNumber - 1)
  const imageUrlsRow = imageUrls.slice(rowAddend, rowLength + rowAddend)

  return (
    <div className={`homepage-links-row row${rowNumber}`}>
      {imageUrlsRow.map((url, index) => {
        let linkIndex = index + rowAddend
        return <img className="homepage-link" key={linkIndex} src={url} alt={`Homepage link to ${urlValues[linkIndex]}`} onClick={() => handleImageClick(linkIndex)} />
      })}
    </div>
  )
}

HomePageLinkRow.propTypes = {
  imageUrls: PropTypes.array.isRequired,
  handleImageClick: PropTypes.func.isRequired,
  numberRows: PropTypes.number.isRequired,
  rowNumber: PropTypes.number.isRequired,
  urlValues: PropTypes.array.isRequired,
}
