/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react'

import { Layout, Popover } from 'antd'
import _ from 'lodash'
import Swal from 'sweetalert2'

import { InstructionDiv, Loading, PageHeader, TrendLineChart, Trending } from '../../components'

import { DataContext, FiltersContext } from '../../providers'

import { filtersAPI } from '../../api'

import {
  // createLineArr,
  findWidth,
  formatFIs, // formatQuarterlyAggData,
  formatQuarterlyAggData2, // formatQuarterlyClassData,
  formatQuarterlyClassData2, // formatQuarterlyFIData,
  formatQuarterlyFIData2,
  formatTrendingColumns, // formatYearlyAggData,
  formatYearlyAggData2, // formatYearlyClassData,
  formatYearlyClassData2, // formatYearlyFIData,
  formatYearlyFIData2,
} from '../../common/utils/helpers'
import { landing, trendingLanding } from './trends-data'
import './trends-page.css'

const { Content } = Layout

export const TrendsPage = () => {
  const {
    isFiltersActive,
    filterSelection,
    setFilterSelection,
    tagSelection,
    setTagSelection,
    // setOpenPanels,
    // selectedTopics,
    // setSelectedTopics,
    // setIsTrendsMentionRate,
    chosenFIs,
    // setValue,
    // setChosenFIs,
    // setFIs,
    isTrendsMentionRate,
    timeValues,
  } = useContext(FiltersContext)
  const {
    isTrendsLoading,
    trendsData,
    trendsTrendingData,
    setTrendsTrendingData,
    setIsTrendingLoading,
    isTrendingLoading,
    // setLandingTrendingData,
    setTrendingResp,
    setResp,
    setTrendsData,
    setTrendsState,
    trendsState,
    pxiData,
    pxiYearlyData,
    mentionData,
    mentionYearlyData,
    isMentionAgg,
    isPXIAgg,
    isMentionFI,
    isPXIFI,
    isMentionClassification,
    isPXIClassification,
    chartLabel,
    linePXIArr,
    lineMentionArr,
    setIsTrendsLoading,
    setLandingTrendsData,
    // landingTrendsData,
    // landingTrendingData,
    isTrendsCallActive,
    setIsTrendsCallActive,
    setTrendsFilterSelection,
  } = useContext(DataContext)
  const [state, setState] = useState({
    isPXIYearly: false,
    isMentionYearly: false,
    width: findWidth(),
    isMentionYear: false,
  })

  const { isMentionYearly, isPXIYearly, width, isMentionYear } = state

  const onClassToggleClick = (dataKey) => {
    let filteredTrendsData = trendsData
    if (!_.isEmpty(chosenFIs)) {
      filteredTrendsData = trendsData.filter((item) => chosenFIs.includes(item._id.fi_id))
    }
    if (dataKey === 'Mention Rate') {
      setTrendsState({
        ...trendsState,
        mentionData: formatQuarterlyClassData2(filteredTrendsData),
        mentionYearlyData: formatYearlyClassData2(filteredTrendsData),
        isMentionAgg: false,
        isMentionClassification: true,
        isMentionFI: false,
        lineMentionArr: ['Bank', 'Credit Union', 'Digital Bank', 'Unclassified'],
        chartLabel: 'Classifications',
      })
    } else if (dataKey === 'PXI') {
      setTrendsState({
        ...trendsState,
        pxiData: formatQuarterlyClassData2(filteredTrendsData),
        pxiYearlyData: formatYearlyClassData2(filteredTrendsData),
        isPXIAgg: false,
        isPXIClassification: true,
        isPXIFI: false,
        linePXIArr: ['Bank', 'Credit Union', 'Digital Bank', 'Unclassified'],
        chartLabel: 'Classifications',
      })
    }
  }
  const onAggToggle = (dataValue) => {
    let filteredTrendsData = trendsData
    if (!_.isEmpty(chosenFIs)) {
      filteredTrendsData = trendsData.filter((item) => chosenFIs.includes(item._id.fi_id))
    }
    if (dataValue === 'PXI') {
      setTrendsState({
        ...trendsState,
        pxiData: formatQuarterlyAggData2(filteredTrendsData, 'PXI'),
        pxiYearlyData: formatYearlyAggData2(filteredTrendsData, 'PXI'),
        isPXIAgg: true,
        isPXIFI: false,
        isPXIClassification: false,
        linePXIArr: ['PXI'],
        chartLabel: 'Aggregation',
      })
    } else if (dataValue === 'Mention Rate') {
      setTrendsState({
        ...trendsState,
        mentionData: formatQuarterlyAggData2(filteredTrendsData, 'Mention Rate'),
        mentionYearlyData: formatYearlyAggData2(filteredTrendsData, 'Mention Rate'),
        isMentionAgg: true,
        isMentionFI: false,
        isMentionClassification: false,
        lineMentionArr: ['Mention Rate'],
        chartLabel: 'Aggregation',
      })
    }
  }
  const onFIToggleClick = (dataValue) => {
    if (!_.isEmpty(chosenFIs) && chosenFIs.length < 11) {
      let filteredTrendsData = trendsData.filter((item) => chosenFIs.includes(item._id.fi_id))
      if (dataValue === 'PXI') {
        setTrendsState({
          ...trendsState,
          pxiData: formatQuarterlyFIData2(filteredTrendsData),
          pxiYearlyData: formatYearlyFIData2(filteredTrendsData),
          isPXIAgg: false,
          isPXIFI: true,
          isPXIClassification: false,
          // linePXIArr: createLineArr(trendsData),
          linePXIArr: chosenFIs,
          chartLabel: 'Financial Institutions',
        })
      } else if (dataValue === 'Mention Rate') {
        setTrendsState({
          ...trendsState,
          mentionData: formatQuarterlyFIData2(filteredTrendsData),
          mentionYearlyData: formatYearlyFIData2(filteredTrendsData),
          isMentionAgg: false,
          isMentionFI: true,
          isMentionClassification: false,
          // lineMentionArr: createLineArr(trendsData),
          lineMentionArr: chosenFIs,
          chartLabel: 'Financial Institution',
        })
      }
    }
  }
  const onYearlyToggle = (dataValue) => {
    if (dataValue === 'PXI') {
      setState({ ...state, isPXIYearly: !isPXIYearly })
    } else if (dataValue === 'Mention Rate') {
      setState({ ...state, isMentionYearly: !isMentionYearly })
    }
  }

  // const resetTopics = () => {
  //   if (_.isEmpty(filterSelection) && _.isEmpty(tagSelection)) {
  //     return
  //   } else {
  //     if (
  //       !_.isEmpty(tagSelection.service) ||
  //       !_.isEmpty(tagSelection.fees) ||
  //       !_.isEmpty(tagSelection.offerings) ||
  //       !_.isEmpty(tagSelection.accessibility) ||
  //       !_.isEmpty(tagSelection.brand) ||
  //       !_.isEmpty(tagSelection.customer) ||
  //       !_.isEmpty(tagSelection.product)
  //     ) {
  //       setTagSelection({})
  //     }

  //     if (
  //       !_.isEmpty(filterSelection.service) ||
  //       !_.isEmpty(filterSelection.fees) ||
  //       !_.isEmpty(filterSelection.offerings) ||
  //       !_.isEmpty(filterSelection.accessibility) ||
  //       !_.isEmpty(filterSelection.brand) ||
  //       !_.isEmpty(filterSelection.customer) ||
  //       !_.isEmpty(filterSelection.product)
  //     ) {
  //       setFilterSelection({})
  //     }
  //     if (selectedTopics.length > 0) {
  //       setSelectedTopics([])
  //     }
  //   }
  // }

  // const countEmptyObjects = (arr) => {
  //   let count = 0
  //   arr.map((obj) => {
  //     if (!_.isEmpty(obj)) {
  //       count++
  //     }
  //   })
  //   return count
  // }

  // const onTabClick = (e) => {
  //   setValue([])
  //   let id
  //   if (e.target.localName === 'span') {
  //     id = e.target.parentNode.dataset.id
  //   } else {
  //     id = e.target.dataset.id
  //   }

  //   if (id) {
  //     if (id === 'mentionRate') {
  //       setOpenPanels(['6'])
  //       setIsTrendsMentionRate(true)
  //       const filterArr = [
  //         filterSelection.service,
  //         filterSelection.fees,
  //         filterSelection.offerings,
  //         filterSelection.accessibility,
  //         filterSelection.brand,
  //         filterSelection.customer,
  //         filterSelection.product,
  //       ]
  //       let count = countEmptyObjects(filterArr)
  //       if (count > 1) {
  //         console.log('resetting')
  //         resetTopics()
  //       }
  //     } else {
  //       setIsTrendsMentionRate(false)
  //     }
  //     const wrapper = document.querySelector('.buttonWrapper').children
  //     const content = document.querySelector('.contentWrapper').children
  //     for (let i = 0; i < wrapper.length; i++) {
  //       const el = wrapper[i]
  //       const conEl = content[i]
  //       if (id === el.dataset.id) {
  //         el.classList.add('btnActive')
  //         conEl.classList.add('active')
  //       } else {
  //         el.classList.remove('btnActive')
  //         conEl.classList.remove('active')
  //       }
  //     }
  //   }
  // }

  //general useEffect, empty callback
  useEffect(() => {
    window.scrollTo(0, 0)

    // sets landing data
    // if (landingTrendingData.length === 0 && landingTrendsData.length === 0) {
    //   // set landing state
    //   setLandingTrendingData(formatTrendingColumns(trendingLanding))
    //   setLandingTrendsData(landing)
    // }

    function resizeHandler() {
      setState({ ...state, width: findWidth() })
    }
    window.addEventListener('resize', resizeHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  //useEffect that listens for isTrendsCallActive
  useEffect(() => {
    if (!isTrendsCallActive && !_.isEmpty(trendsData)) {
      // setFIs(formatFIs(trendsData))
      return
    }
    const sentData = {
      chosenFIs: chosenFIs,
      filterSelection,
    }
    // console.log(sentData)

    if (isTrendsCallActive) {
      setTagSelection({
        ...tagSelection,
        time: timeValues.filter((time) => !isNaN(parseInt(time.charAt(0))) || time.charAt(0) === 'Q'),
      })

      setFilterSelection({
        ...filterSelection,
        time: {
          year: filterSelection.time.year,
          quarter: filterSelection.time.quarter,
          month: [],
        },
      })
      setIsTrendsLoading(true)
      //find out if we're on PXI tab or Mention Rate tab
      if (isTrendsMentionRate) {
        // console.log('using another tab')
        //if on Mention Rate Tab first check that only one topic is selected
        if (!filterSelection.isMultipleTopics) {
          filtersAPI.getFilterSelectionsTest('/trends', sentData).then((data) => {
            // filtersAPI.getFilterSelections('/trends', sentData).then((data) => {
            // console.log('datpppppppppppppppppppppppppppppppppppppppppppa', data)
            if (!_.isEmpty(data)) {
              setTrendsFilterSelection(filterSelection)
              //get trending data
              filtersAPI.getFilterSelectionsTest('/trends/trending', sentData).then((trendingData) => {
                // filtersAPI.getFilterSelections('/trends/trending', sentData).then((trendingData) => {
                // console.log('3333333333', trendingData)
                // const trendsFIs = data.map(({ _id }) => _id)
                // const filteredTrending = trendingData.filter((res) => trendsFIs.includes(res._id))
                const filteredTrending = Object.keys(trendingData).map((key) => {
                  return { _id: key, ...trendingData[key] }
                })
                setTrendsTrendingData(formatTrendingColumns(filteredTrending))
                setTrendingResp(filteredTrending)
                setIsTrendingLoading(false)
              })

              setIsTrendsLoading(false)
              setResp(data)
              setTrendsData(data)
              // setFIs(formatFIs(data))
              setIsTrendsCallActive(false)
              if (isMentionClassification) {
                setTrendsState({
                  ...trendsState,
                  mentionData: formatQuarterlyClassData2(data, 'Mention Rate'),
                  mentionYearlyData: formatYearlyClassData2(data, 'Mention Rate'),
                })
              } else {
                setTrendsState({
                  ...trendsState,
                  mentionData: formatQuarterlyAggData2(data, 'Mention Rate'),
                  mentionYearlyData: formatYearlyAggData2(data, 'Mention Rate'),
                  lineMentionArr: ['Mention Rate'],
                  chartLabel: 'Aggregation',
                  isMentionAgg: true,
                  isMentionFI: false,
                })
              }
              //if on Mention Rate Tab and multiple topics selected send this message
            } else {
              setIsTrendsLoading(false)
              setIsTrendingLoading(false)
              setIsTrendsCallActive(false)
              setTagSelection({})
              setFilterSelection({})
              Swal.fire({
                text: 'Unfortunately these particular Trends Page filter selections yielded no results. Please select different filters and try again.',
              })
            }
          })
          // filtersAPI.getFilterSelectionsTest('/trends', sentData).then((data) => {
          //   console.log('test', data)
          // })
        } else {
          setIsTrendsCallActive(false)
          Swal.fire({
            text: 'Only one topic or topic category may be selected at a time. Please open up the filters and adjust your selections.',
          })
        }
        //if not on Mention Rate tab
      } else {
        filtersAPI.getFilterSelectionsTest('/trends', sentData).then((data) => {
          // filtersAPI.getFilterSelections('/trends', sentData).then((data) => {
          if (!_.isEmpty(data)) {
            setTrendsFilterSelection(filterSelection)
            //get trending data
            filtersAPI.getFilterSelectionsTest('/trends/trending', sentData).then((trendingData) => {
              // filtersAPI.getFilterSelections('/trends/trending', sentData).then((trendingData) => {
              const filteredTrending = Object.keys(trendingData).map((key) => {
                return { _id: key, ...trendingData[key] }
              })
              setTrendsTrendingData(formatTrendingColumns(filteredTrending))
              setTrendingResp(filteredTrending)
              setIsTrendingLoading(false)
            })
            setIsTrendsLoading(false)
            setResp(data)
            setTrendsData(data)
            // setTrendsData(data.filter(({ _id }) => trendsData.map(({ _id }) => _id).includes(_id)))
            // setLandingTrendingData(data.filter(({ _id }) => trendsData.map(({ _id }) => _id).includes(_id)))
            // setFIs(formatFIs(data))
            setIsTrendsCallActive(false)
            if (isPXIClassification) {
              // console.log(data)
              // console.log(formatQuarterlyClassData2(data, 'PXI'))
              setTrendsState({
                ...trendsState,
                pxiData: formatQuarterlyClassData2(data, 'PXI'),
                pxiYearlyData: formatYearlyClassData2(data, 'PXI'),
              })
            } else {
              // console.log(formatQuarterlyAggData2(data, 'PXI'))
              // console.log(formatYearlyAggData2(data, 'PXI'))
              setTrendsState({
                ...trendsState,
                pxiData: formatQuarterlyAggData2(data, 'PXI'),
                pxiYearlyData: formatYearlyAggData2(data, 'PXI'),
                isPXIAgg: true,
                ixPXIFI: false,
                linePXIArr: ['PXI'],
                chartLabel: 'Aggregation',
              })
            }
            //if on Mention Rate Tab and multiple topics selected send this message
          } else {
            setIsTrendsLoading(false)
            setIsTrendingLoading(false)
            setIsTrendsCallActive(false)
            setTagSelection({})
            setFilterSelection({})
            Swal.fire({
              text: 'Unfortunately these particular Trends Page filter selections yielded no results. Please select different filters and try again.',
            })
          }
        })
      }
    }

    //if no trending data and no filters selected set trending to landing data
    if (_.isEmpty(trendsTrendingData) && _.isEmpty(filterSelection)) {
      setTrendsTrendingData(formatTrendingColumns(trendingLanding))
      setIsTrendingLoading(false)
      setTrendingResp(trendingLanding)
      setResp(landing)
    }

    //if no trend chart data and no filters selected set trending to landing data
    if (_.isEmpty(filterSelection) && !_.isEmpty(trendsData)) {
      // setTrendsState({
      //   ...trendsState,
      //   pxiData: formatQuarterlyAggData(landing, 'PXI'),
      //   pxiYearlyData: formatYearlyAggData(landing, 'PXI'),
      //   mentionData: formatQuarterlyAggData(landing, 'Mention Rate'),
      //   mentionYearlyData: formatYearlyAggData(landing, 'Mention Rate'),
      //   chartLabel: 'Aggregation',
      //   linePXIArr: ['PXI'],
      //   lineMentionArr: ['Mention Rate'],
      // })
      // setIsTrendsLoading(false)

      // if (chosenFIs && chosenFIs.length) {
      const emptyFilterSelection = {
        filterSelection: {
          // chosenFIs: chosenFIs,
          filterSelection: {
            sources: [],
            classifications: [],
            sizes: [],
            time: {},
            customerService: [],
            feesAndIncentives: [],
            productOfferings: [],
            accessibility: [],
            brandEquity: [],
            designAndFunctionality: [],
            customer: [],
            product: [],
            isCustomerServiceToggleAnd: false,
            isFeesAndIncentivesToggleAnd: false,
            isDesignAndFunctionalityToggleAnd: false,
            isProductOfferingsToggleAnd: false,
            isAccessibilityToggleAnd: false,
            isBrandEquityToggleAnd: false,
            isCustomerToggleAnd: false,
            isProductToggleAnd: false,
            pathname: '/trends',
            isTrendsMentionRate: false,
            isMultipleTopics: true,
          },
        },
      }

      filtersAPI.getFilterSelectionsTest('/trends', emptyFilterSelection).then((data) => {
        if (!_.isEmpty(data)) {
          // setTrendsFilterSelection(filterSelection)
          //get trending data
          // filtersAPI.getFilterSelectionsTest('/trends/trending', emptyFilterSelection).then((trendingData) => {
          //   const filteredTrending = Object.keys(trendingData).map((key) => {
          //     return { _id: key, ...trendingData[key] }
          //   })
          //   setLandingTrendingData(formatTrendingColumns(filteredTrending))
          //   setTrendsTrendingData(formatTrendingColumns(filteredTrending))
          //   setTrendingResp(filteredTrending)
          //   setIsTrendingLoading(false)
          // })
          setIsTrendsLoading(false)
          setResp(data)
          setLandingTrendsData(landing)
          setTrendsData(data)
          // setTrendsData(data.filter(({ _id }) => trendsData.map(({ _id }) => _id).includes(_id)))
          // setLandingTrendingData(data.filter(({ _id }) => trendsData.map(({ _id }) => _id).includes(_id)))
          // setFIs(formatFIs(data))
          setIsTrendsCallActive(false)
          if (isPXIClassification) {
            setTrendsState({
              ...trendsState,
              pxiData: formatQuarterlyClassData2(data, 'PXI'),
              pxiYearlyData: formatYearlyClassData2(data, 'PXI'),
            })
          } else {
            setTrendsState({
              ...trendsState,
              pxiData: formatQuarterlyAggData2(data, 'PXI'),
              pxiYearlyData: formatYearlyAggData2(data, 'PXI'),
              isPXIAgg: true,
              ixPXIFI: false,
              linePXIArr: ['PXI'],
              chartLabel: 'Aggregation',
            })
          }
          // if on Mention Rate Tab and multiple topics selected send this message
        } else {
          setIsTrendsLoading(false)
          setIsTrendingLoading(false)
          setIsTrendsCallActive(false)
          setTagSelection({})
          setFilterSelection({})
          Swal.fire({
            text: 'Unfortunately these particular Trends Page filter selections yielded no results. Please select different filters and try again.',
          })
        }
      })
      // }
    }
  }, [isTrendsCallActive])

  /* POPOVER DATA */
  const pxiPopoverData = (
    <div className="title-popover">
      PXI stands for Product Experience Indicator, and it is calculated by taking % of positive reviews (5 stars) and subtracting % of negative reviews (1-3 stars) divided by the aggregation of all
      reviews. This metric is useful in benchmarking against competition, in assessing performance over time, and in identifying risks and opportunities. It is calculated at all levels of the product
      hierarchy from Brand, to product category, all the way down to individual product.{' '}
    </div>
  )

  return (
    <>
      <Content className="page-content">
        <Loading isLoading={isTrendsLoading}>
          <PageHeader
            title="Trends"
            description="Toggle between time periods to see an overarching view of PXI and Topic Mention Rate. Drill down to see trends for high-level groupings and each financial institution selected."
          >
            <div style={{ display: 'block', justifyContent: 'center' }}>
              <div className="tabWrapper">
                <div className="buttonWrapper">
                  <Popover content={pxiPopoverData} placement="topRight" title="PXI Definition">
                    <button
                      className="tab-button btnActive"
                      // onClick={(e) => {
                      //   onTabClick(e)
                      // }}
                      data-id="pxi"
                    >
                      PXI{' '}
                    </button>
                  </Popover>
                </div>
                <div className="contentWrapper">
                  <div className="content active" id="pxi">
                    <TrendLineChart
                      data={isPXIYearly ? pxiYearlyData : pxiData}
                      lineArr={linePXIArr}
                      height={screen.height * 0.6}
                      width={width}
                      dataValue="PXI"
                      chartType={chartLabel}
                      legendName={isPXIYearly ? 'Yearly View' : 'Quarterly View'}
                      btnClassClass={isPXIClassification ? 'trends-btn-active' : ''}
                      btnFIClass={isPXIFI ? 'trends-btn-active' : ''}
                      btnAggClass={isPXIAgg ? 'trends-btn-active' : ''}
                      onAggToggle={onAggToggle}
                      onClassifiClick={onClassToggleClick}
                      onFIClick={onFIToggleClick}
                      onYearlyToggle={onYearlyToggle}
                      timeBtnName={isPXIYearly ? 'Show Quarterly View' : 'Show Yearly View'}
                    />
                  </div>
                  <div className="content" id="mentionRate">
                    {isFiltersActive ? (
                      <TrendLineChart
                        data={isMentionYearly ? mentionYearlyData : mentionData}
                        lineArr={lineMentionArr}
                        //height={lineMentionArr.length > 4 ? screen.height * 0.6 : height}
                        height={screen.height * 0.6}
                        width={width}
                        dataValue="Mention Rate"
                        chartType={chartLabel}
                        legendName={isMentionYearly ? 'Yearly View' : 'Quarterly View'}
                        periodName={isMentionYear ? 'Show Quarterly View' : 'Show Yearly View'}
                        btnClassClass={isMentionClassification ? 'trends-btn-active' : ''}
                        btnFIClass={isMentionFI ? 'trends-btn-active' : ''}
                        btnAggClass={isMentionAgg ? 'trends-btn-active' : ''}
                        onAggToggle={onAggToggle}
                        onClassifiClick={onClassToggleClick}
                        onFIClick={onFIToggleClick}
                        onYearlyToggle={onYearlyToggle}
                        timeBtnName={isMentionYearly ? 'Show Quarterly View' : 'Show Yearly View'}
                      />
                    ) : (
                      <InstructionDiv />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Trending isLoading={isTrendingLoading} trendingData={trendsTrendingData} />
          </PageHeader>
        </Loading>
      </Content>
    </>
  )
}
