/* eslint-disable no-unused-vars */
import { useContext, useState } from 'react'

import { Button, Col, Input, Row } from 'antd'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'

import { Loading, ReviewTag, ToggleSwitch } from '../../components'

import { DataContext, FiltersContext } from '../../providers'

import { reviewsAPI } from '../../api'

import { changeKeyData, formatReviewFIs, formatReviews } from '../../common/utils/helpers'

const { Search } = Input

export const ReviewControls = (props) => {
  const { expandName, handleExpandClick, handleToggle, isToggleAnd, pxi, pxiLoading } = props
  const {
    totalPerPage,
    setIsReviewsLoading,
    setReviewsData,
    setTotalReviews,
    setReviewsText,
    reviewsText,
    setIsReviewsPXILoading,
    setReviewsPXI,
    setTotalReviewsTextAndNoText,
    setCurrentPage,
    setIsUpdatePXI,
    isReviewCardExpanded,
    setReviewCsvData,
  } = useContext(DataContext)
  const { filterSelection, chosenFIs, setFIs } = useContext(FiltersContext)
  const [inputValue, setInputValue] = useState('')

  const fetchReviewsText = async (updatedReviews) => {
    reviewsAPI.putReviews(totalPerPage, chosenFIs, filterSelection, updatedReviews, isToggleAnd).then(({ data, total, fis }) => {
      setIsReviewsLoading(false)
      if (!_.isEmpty(data)) {
        const updatedKeyData = changeKeyData(data)
        setReviewCsvData(updatedKeyData)
        setCurrentPage(1)
        const newData = formatReviews(data, isReviewCardExpanded)
        setReviewsData(newData)
        setTotalReviews(total)
        // if (!_.isEmpty(fis)) setFIs(formatReviewFIs(fis))
      } else {
        reviewsText.pop()
        const newArr = [...reviewsText]
        setReviewsText(newArr)
        Swal.fire({
          text: 'Unfortunately these particular selections yielded no reviews. Please select different filters and try again.',
        })
      }
    })
  }

  const searchText = (text) => {
    setIsReviewsLoading(true)
    setIsReviewsPXILoading(true)
    fetchReviewsText(text)
    fetchPXI(text)
    setIsUpdatePXI(true)
  }

  const fetchPXI = (updatedText) => {
    reviewsAPI.putReviewsPXI(filterSelection, chosenFIs, updatedText, isToggleAnd).then((data) => {
      setIsReviewsPXILoading(false)
      if (data) {
        setReviewsPXI(data)
        // setTotalReviewsTextAndNoText(data.totalTextAndNoText)
        // setReviewsPXI(data.pxi)
      }
    })
  }

  const updateReviewsText = (input) => {
    if (input) {
      if (!reviewsText.includes(input)) {
        reviewsText.push(input)
        const newArr = [...reviewsText]
        searchText(newArr)
        setReviewsText(newArr)
      }
      setInputValue('')
    }
  }

  const onClose = (e, current) => {
    e.preventDefault()

    const filteredArr = reviewsText.filter((word) => word !== current)
    setReviewsText(filteredArr)
    searchText(filteredArr)
  }

  //BTN HANDLER FOR DOWNLOAD CSV
  // const handleOnClick = (e) => {
  //   console.log(e)
  // }

  const data = (
    <div style={{ color: 'var(--hearful-blue)' }} className="title-popover">
      <p>Toggling to &quot;and&quot; will search for reviews that contain all of your choices.</p>
      <p>
        For example, if you search for the words &quot;friendly&quot; and &quot;quick&quot; and have the toggler set to &quot;and&quot;, then you will only see reviews containing both of those search
        words.
      </p>
      <p>If you have the toggler set to &quot;or&quot; then the reviews will contain at least one, but not necessarily both, search words.</p>
      <p>If you would like to search a phrase, such as &quot;great app&quot;, be sure to wrap it in quotations.</p>
    </div>
  )

  return (
    <>
      <div className="review-controls-row">
        <Loading isLoading={pxiLoading}>
          {/* <span className="review-controls-total">Total Reviews: {total}</span> */}
          <span className="review-controls-pxi">PXI: {pxi}</span>
        </Loading>
        <Button
          className="reviews-expand-all"
          onClick={(event) => {
            handleExpandClick(event)
          }}
        >
          {expandName}
        </Button>
        <div className="reviews-search">
          <Input.Group compact>
            <Search
              allowClear
              placeholder="Search in Review..."
              onSearch={updateReviewsText}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              enterButton
              suffix={
                <ToggleSwitch
                  key={`review-controls-toggle`}
                  checked={isToggleAnd}
                  onChange={(input) => {
                    handleToggle(input)
                  }}
                  content={data}
                  title=""
                />
              }
            />
          </Input.Group>
        </div>
      </div>
      {reviewsText.length > 0 ? (
        <div className="review-controls-row">
          <span className="search-label">Searched: </span>
          {reviewsText.map((searchedWord, i) => (
            <ReviewTag key={i} name={searchedWord} onClose={(e) => onClose(e, searchedWord)} />
          ))}
        </div>
      ) : null}
    </>
  )
}

ReviewControls.propTypes = {
  expandName: PropTypes.object.isRequired,
  handleExpandClick: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  isToggleAnd: PropTypes.bool.isRequired,
  pxi: PropTypes.any.isRequired,
  pxiLoading: PropTypes.bool.isRequired,
  // total: PropTypes.any.isRequired,
}
