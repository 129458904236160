/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import { forwardRef, useContext } from 'react'
import { useLocation } from 'react-router-dom'

import { Button, Collapse, Modal, Select, Slider } from 'antd'
import _ from 'lodash'
// import { Auth } from 'aws-amplify'
import PropTypes from 'prop-types'

import { Checkboxes, TimeTabs } from '../../components'

import { FiltersContext, ThemeContext } from '../../providers'

// import { usersAPI } from '../../api'
import { lowercase, yearQuarterDecimal } from '../../common/utils/helpers'
import './modal-component.css'

const { Panel } = Collapse
const { Option } = Select

export const ModalComponent = forwardRef((props, ref) => {
  const { pathname } = useLocation()
  // const [yearRange, setYearRange] = useState([moment().subtract(5, 'year').year(), moment().year()])
  // const [quarterRange, setQuarterRange] = useState(['Q1', 'Q4'])

  // Handler for slider change
  const {
    // classifications,
    // sizes,
    sources,
    stars,
    topics,
    customerServiceValues,
    feesAndIncentivesValues,
    productOfferingsValues,
    brandEquityValues,
    accessibilityValues,
    designAndFunctionalityValues,
    topicFilterValues,
    topicTitle,
    selectedTopics,
    attributes,
    customer_attrs,
    product_attrs,
    customerValues,
    productValues,
    attributeFilterValues,
    openPanels,
    isTrendsMentionRate,
    sourceValues,
    starValues,
    setTimeFlag,
    yearRange,
    setYearRange,
    quarterRange,
    setQuarterRange,
    sliderValue,
    setSliderValue,
    setSelectedTopics,
    setTopicFilterValues,
  } = useContext(FiltersContext)
  const { userInfo } = useContext(ThemeContext)

  const {
    isModalOpen,
    handleOk,
    handleCancel,
    classificationValues,
    sizeValues,
    isExpanded,
    isCheckboxDisabled,
    isCustomerServiceToggleAnd,
    isFeesAndIncentivesToggleAnd,
    isProductOfferingsToggleAnd,
    isAccessibilityToggleAnd,
    isBrandEquityToggleAnd,
    isDesignAndFunctionalityToggleAnd,
    isCustomerToggleAnd,
    isProductToggleAnd,
    handleResetFilter,
    handleSubmit,
    handleExpandAll,
    handleCheckboxChange,
    handleSelectAll,
    handleAndOrChange,
    checkSelections,
    checkSelectionsChanged,
    savedFilterSet,
    setSavedFilterSet,
    mapValuesToTitles,
    mapTimesToObject,
    callback,
    callbackTopics,
    openTopicPanels,
  } = props
  const [topicValue, setTopicValue] = useState('Select a topic')
  const today = new Date()
  const fiveYearsAgo = today.getFullYear() - 5

  const handleSliderChange = (value) => {
    setSliderValue(value)
    const startYear = Math.floor(value[0])
    const startQuarter = value[0] - startYear
    const endYear = Math.floor(value[1])
    const endQuarter = value[1] - endYear
    setYearRange([value[0], value[1]])
    // setQuarterRange([quarterMarks[startQuarter * 4 + 1], quarterMarks[endQuarter * 4 + 1]])
    setQuarterRange([quarterMarks[startQuarter], quarterMarks[endQuarter]])

    // const yearStart = moment().subtract(value[0], 'year').year();
    // const yearEnd = moment().subtract(value[1], 'year').year();
    // setYearRange([yearStart, yearEnd]);
    // setQuarterRange([1, 4]);
    // console.log(value, startQuarter, endQuarter)
  }

  // Marks for quarter slider
  const quarterMarks = {
    0: 'Q1',
    0.25: 'Q2',
    0.5: 'Q3',
    0.75: 'Q4',
  }
  const [savedFilterSets, setSavedFilterSets] = useState([])

  const [timeData, setTimeData] = useState({
    month: [],
    quarter: [],
    year: [],
  })

  useEffect(() => {
    if (!_.isEmpty(userInfo)) {
      const { savedFilterSets } = userInfo
      setSavedFilterSets(savedFilterSets)
    }
  }, [savedFilterSet])

  useEffect(() => {
    if (topicTitle === '') {
      setTopicValue('Select a topic')
    }
  }, [topicTitle])

  useEffect(() => {
    // Check if yearRange has changed
    if (yearRange[0] !== fiveYearsAgo || yearRange[1] !== today.getFullYear()) {
      // Update status flag to 1
      setTimeFlag(1)
    } else {
      setTimeFlag(0)
    }
  }, [yearRange])

  const createFilterTags = (e) => {
    let dataId
    if (e.target.localName === 'span') {
      dataId = e.target.parentElement.dataset.id
    } else {
      dataId = e.target.dataset.id
    }
    const dataArr = savedFilterSets[dataId].filterSelection
    let savedFilterSources = []
    if (dataArr.sources) {
      for (let i = 0; i < dataArr.sources.length; i++) {
        savedFilterSources.push(dataArr.sources[i]['value'])
      }
      handleCheckboxChange(savedFilterSources, 'source')
    }
    // let savedFilterClassifications = []
    // if (dataArr.classifications) {
    //   for (let i = 0; i < dataArr.classifications.length; i++) {
    //     savedFilterClassifications.push(dataArr.classifications[i]['value'])
    //   }
    //   handleCheckboxChange(savedFilterClassifications, 'classification')
    // }
    // let savedFilterSizes = []
    // if (dataArr.sizes) {
    //   for (let i = 0; i < dataArr.sizes.length; i++) {
    //     savedFilterSizes.push(dataArr.sizes[i]['value'])
    //   }
    //   handleCheckboxChange(savedFilterSizes, 'size')
    // }
    let savedFilterCustomers = []
    if (dataArr.customer) {
      for (let i = 0; i < dataArr.customer.length; i++) {
        savedFilterCustomers.push(dataArr.customer[i]['value'])
      }
    }
    let savedFilterProduct = []
    if (dataArr.product) {
      for (let i = 0; i < dataArr.product.length; i++) {
        savedFilterProduct.push(dataArr.product[i]['value'])
      }
    }
    handleCheckboxChange({ customerValues: savedFilterCustomers, productValues: savedFilterProduct }, 'productCustomer')
    let savedFilterServices = []
    if (dataArr.service) {
      for (let i = 0; i < dataArr.service.length; i++) {
        savedFilterServices.push(dataArr.service[i]['value'])
      }
    }
    let savedFilterfees = []
    if (dataArr.fees) {
      for (let i = 0; i < dataArr.fees.length; i++) {
        savedFilterfees.push(dataArr.fees[i]['value'])
      }
    }
    let savedFilterOfferings = []
    if (dataArr.offerings) {
      for (let i = 0; i < dataArr.offerings.length; i++) {
        savedFilterOfferings.push(dataArr.offerings[i]['value'])
      }
    }
    let savedFilterAccessibilitys = []
    if (dataArr.accessibility) {
      for (let i = 0; i < dataArr.accessibility.length; i++) {
        savedFilterAccessibilitys.push(dataArr.accessibility[i]['value'])
      }
    }
    let savedFilterBrands = []
    if (dataArr.brand) {
      for (let i = 0; i < dataArr.brand.length; i++) {
        savedFilterBrands.push(dataArr.brand[i]['value'])
      }
    }
    let savedFilterDesigns = []
    if (dataArr.design) {
      for (let i = 0; i < dataArr.design.length; i++) {
        savedFilterDesigns.push(dataArr.design[i]['value'])
      }
    }
    handleCheckboxChange(
      {
        customerServiceValues: savedFilterServices,
        feesAndIncentivesValues: savedFilterfees,
        productOfferingsValues: savedFilterOfferings,
        brandEquityValues: savedFilterAccessibilitys,
        accessibilityValues: savedFilterBrands,
        designAndFunctionalityValues: savedFilterDesigns,
      },
      'topic',
    )
    if (dataArr.time) {
      let finalTime = { ...dataArr.time }
      setTimeData(finalTime)
    }
  }

  const handleSelectChange = (value) => {
    setTopicValue(value)
    const selectedTopic = topics.filter((item) => item.label === value)
    setSelectedTopics(selectedTopic)
    const unselectedTopic = topics.filter((item) => item.label !== value)
    const filterType = lowercase(value.replace(/\s+/g, ''))
    let filterArr
    topics.map((item) => {
      if (item.label === value) {
        filterArr = item.children
      }
    })
    //need to select all the values from the chosen topic
    const values = []
    for (let i = 0; i < filterArr.length; i++) {
      values.push(i.toString())
    }
    let obj = {}
    unselectedTopic.map((item, i) => {
      obj[i.toString()] = lowercase(item.label.replace(/\s+/g, ''))
    })
    setTopicFilterValues({
      ...topicFilterValues,
      [`${filterType}Values`]: values,
      [`${filterType}TopicValues`]: values,
      [`${obj['0']}Values`]: [],
      [`${obj['1']}Values`]: [],
      [`${obj['2']}Values`]: [],
      [`${obj['3']}Values`]: [],
      [`${obj['4']}Values`]: [],
      [`${obj['5']}Values`]: [],
      [`${obj['6']}Values`]: [],
    })
  }

  const formatter = (value) => `${Math.floor(value)} ${quarterMarks[value - Math.floor(value)]}`

  return (
    <Modal
      open={isModalOpen}
      title="Select Your Filters"
      maskClosable={true}
      onOk={handleOk}
      onCancel={handleCancel}
      bodyStyle={{ maxHeight: '57vh', overflowY: 'auto', overflowX: 'hidden' }}
      footer={[
        <div style={{ display: 'flex', justifyContent: 'space-between' }} key="footer-div">
          <Button type="link" key="clear" className="clear-filters-button" onClick={handleResetFilter}>
            Clear Filters
          </Button>
          {checkSelectionsChanged() && (
            <>
              {/* <Button
                  key="save"
                  className="modal-submit-btn"
                  onClick={() =>
                    setSavedFilterSet({
                      ...savedFilterSet,
                      filterSelection: {
                        sources: mapValuesToTitles(sourceValues, sources, 'source'),
                        classifications: mapValuesToTitles(classificationValues, classifications, 'classification'),
                        sizes: mapValuesToTitles(sizeValues, sizes, 'size'),
                        time: mapTimesToObject(),
                        customerService: mapValuesToTitles(customerServiceValues, findSubTopicChildren(topics, 'Customer Service')),
                        feesAndIncentives: mapValuesToTitles(feesAndIncentivesValues, findSubTopicChildren(topics, 'Fees And Incentives')),
                        productOfferings: mapValuesToTitles(productOfferingsValues, findSubTopicChildren(topics, 'Product Offerings')),
                        accessibility: mapValuesToTitles(accessibilityValues, findSubTopicChildren(topics, 'Accessibility')),
                        brandEquity: mapValuesToTitles(brandEquityValues, findSubTopicChildren(topics, 'Brand Equity')),
                        designAndFunctionality: mapValuesToTitles(designAndFunctionalityValues, findSubTopicChildren(topics, 'Design And Functionality')),
                        customer: mapValuesToTitles(customerValues, customer_attrs),
                        product: mapValuesToTitles(productValues, product_attrs),
                        isCustomerServiceToggleAnd,
                        isFeesAndIncentivesToggleAnd,
                        isDesignAndFunctionalityToggleAnd,
                        isProductOfferingsToggleAnd,
                        isAccessibilityToggleAnd,
                        isBrandEquityToggleAnd,
                        isCustomerToggleAnd,
                        isProductToggleAnd,
                      },
                    })
                  }
                >
                  Save this filter set
                </Button> */}
              <Button key="submit" className="modal-submit-btn" onClick={handleSubmit}>
                Submit
              </Button>
            </>
          )}
          {/* <Button key="submit" className="modal-submit-btn" onClick={handleSubmit}>
            Submit
          </Button> */}
        </div>,
      ]}
    >
      {/* <div className="saved-filters-div">
        <h4 className="title">Saved Filter Sets</h4>
        {!_.isEmpty(savedFilterSets) ? (
          <div className="saved-filters-btns-div">
            {savedFilterSets.map((set, i) => (
              <Button key={i} data-id={i} style={{ marginTop: '5px' }} className="blue-button center-flex savedFIBtn custom-button" onClick={createFilterTags}>
                {set.name}
              </Button>
            ))}
          </div>
        ) : (
          <p>You have not yet saved a set of filters.</p>
        )}
      </div> */}
      <div className="ant-modal-toggles">
        <Button key="expand-or-collapse" type="link" onClick={handleExpandAll}>
          {isExpanded ? 'Collapse All' : 'Expand All'}
        </Button>
      </div>
      <Collapse ghost={true} activeKey={openPanels} onChange={callback}>
        {!_.isEmpty(stars) && pathname === '/reviews' && (
          <Panel header="Star Rating" key="star">
            <Checkboxes isSwitchVisible={false} onChange={handleCheckboxChange} filterType="star" handleSelectAll={handleSelectAll} apiData={stars} values={starValues} />
          </Panel>
        )}
        {!_.isEmpty(sources) && pathname !== '/data' && pathname !== '/forums' && (
          <Panel header="Sources" key="source">
            {<Checkboxes isSwitchVisible={false} onChange={handleCheckboxChange} filterType="source" handleSelectAll={handleSelectAll} apiData={sources} values={sourceValues} />}
          </Panel>
        )}
        {/* {!_.isEmpty(classifications) && pathname !== '/forums' && (
          <Panel header="CLASSIFICATIONS" key="2">
            <Checkboxes isSwitchVisible={false} onChange={handleCheckboxChange} filterType="classification" handleSelectAll={handleSelectAll} apiData={classifications} values={classificationValues} />
          </Panel>
        )} */}
        {/* {!_.isEmpty(sizes) && pathname !== '/forums' && (
          <Panel header="SIZES" key="3">
            <Checkboxes isSwitchVisible={false} onChange={handleCheckboxChange} filterType="size" handleSelectAll={handleSelectAll} apiData={sizes} values={sizeValues} />
          </Panel>
        )} */}
        {/* {pathname !== '/reviews' ? ( */}
        <Panel header="Time Period" key="time">
          <div>
            <Slider range min={fiveYearsAgo} max={yearQuarterDecimal(today)} value={sliderValue} onChange={handleSliderChange} tooltip={{ formatter }} step={0.25} />
            <div className="sliderrange">
              {Math.floor(sliderValue[0])} {quarterRange[0]} - {Math.floor(sliderValue[1])} {quarterRange[1]}
            </div>
          </div>
        </Panel>
        {/* ) : (
          <Panel header="TIME PERIOD" key="4">
            <div ref={ref}>
              <TimeTabs mapTimesToObject={mapTimesToObject} timeData={timeData} />
            </div>
          </Panel>
        )} */}
        {pathname !== '/topics' && pathname !== '/drivers' && pathname !== '/trends' && !isTrendsMentionRate && !_.isEmpty(topics) && !_.isEmpty(topicFilterValues) ? (
          <Panel header="Topics" key="6">
            <Collapse ghost={true} activeKey={openTopicPanels} onChange={callbackTopics}>
              {topics.map((item, i) => {
                if (item.label !== 'Customer' && item.label !== 'Product') {
                  const checkValues = topicFilterValues[`${lowercase(item.label.replace(/\s+/g, ''))}Values`]
                  return (
                    <Panel header={item.label} key={i}>
                      <Checkboxes
                        isDisabled={isCheckboxDisabled}
                        isSwitchVisible={false}
                        handleAndOrChange={handleAndOrChange}
                        filterType={lowercase(item.label).replace(/\s+/g, '')}
                        isToggleAnd={props[`is${item.label.replace(/\s+/g, '')}ToggleAnd`]}
                        onChange={handleCheckboxChange}
                        handleSelectAll={handleSelectAll}
                        apiData={item.children}
                        values={checkValues}
                        category={item.category}
                      />
                    </Panel>
                  )
                }
              })}
            </Collapse>
          </Panel>
        ) : (
          (pathname === '/topics' || pathname === '/trends' || isTrendsMentionRate) && (
            <Panel header="Topics" key="6">
              <Collapse ghost={true} activeKey={openTopicPanels} onChange={callbackTopics}>
                <div className="single-topic-select">
                  <Select
                    defaultValue={'Select a topic'}
                    style={{
                      width: 300,
                    }}
                    onChange={handleSelectChange}
                    value={topicValue}
                  >
                    {topics.map((item) => {
                      if (item.label !== 'Customer' && item.label !== 'Product') {
                        return (
                          <Option value={item.label} key={item.label}>
                            {item.label}
                          </Option>
                        )
                      }
                    })}
                  </Select>
                </div>
                {/* {topics.map((item, i) => {
                  return (
                    <Panel header={item.label} key={i}>
                      <Checkboxes
                        isDisabled={isCheckboxDisabled}
                        isSwitchVisible={false}
                        handleAndOrChange={handleAndOrChange}
                        filterType={lowercase(item.label).replace(/\s+/g, '')}
                        isToggleAnd={props[`is${item.label.replace(/\s+/g, '')}ToggleAnd`]}
                        onChange={handleCheckboxChange}
                        handleSelectAll={handleSelectAll}
                        apiData={item.children}
                        values={topicFilterValues[`${lowercase(item.label.replace(/\s+/g, ''))}Values`]}
                        category={item.category}
                      />
                    </Panel>
                  )
                })} */}
              </Collapse>
            </Panel>
          )
        )}
        {_.isEmpty(customer_attrs) &&
          _.isEmpty(product_attrs) &&
          attributes.map((item, i) => {
            return (
              <Panel header={item.label} key={i}>
                <Checkboxes
                  isDisabled={isCheckboxDisabled}
                  isSwitchVisible={false}
                  handleAndOrChange={handleAndOrChange}
                  filterType={lowercase(item.label).replace(/\s+/g, '')}
                  isToggleAnd={props[`is${item.label.replace(/\s+/g, '')}ToggleAnd`]}
                  onChange={handleCheckboxChange}
                  handleSelectAll={handleSelectAll}
                  apiData={item.children}
                  values={attributeFilterValues[`${lowercase(item.label.replace(/\s+/g, ''))}Values`]}
                />
              </Panel>
            )
          })}
        {customer_attrs && (
          <Panel header="Customer">
            <Checkboxes
              isDisabled={isCheckboxDisabled}
              isSwitchVisible={false}
              handleAndOrChange={handleAndOrChange}
              filterType="customer"
              isToggleAnd={props[`isCustomerToggleAnd`]}
              onChange={handleCheckboxChange}
              handleSelectAll={handleSelectAll}
              apiData={customer_attrs.map((item) => ({
                ...item,
                label: _.startCase(item.label),
              }))}
              values={attributeFilterValues[`customerValues`]}
            />
          </Panel>
        )}
        {product_attrs && (
          <Panel header="Product">
            <Checkboxes
              isDisabled={isCheckboxDisabled}
              isSwitchVisible={false}
              handleAndOrChange={handleAndOrChange}
              filterType="product"
              isToggleAnd={props[`isProductToggleAnd`]}
              onChange={handleCheckboxChange}
              handleSelectAll={handleSelectAll}
              apiData={product_attrs.map((item) => ({
                ...item,
                label: _.startCase(item.label),
              }))}
              values={attributeFilterValues[`productValues`]}
            />
          </Panel>
        )}
      </Collapse>
    </Modal>
  )
})

ModalComponent.displayName = 'ModalComponent'

ModalComponent.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  classificationValues: PropTypes.array.isRequired,
  sizeValues: PropTypes.array.isRequired,
  timeValues: PropTypes.array.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isCheckboxDisabled: PropTypes.bool.isRequired,
  isCustomerServiceToggleAnd: PropTypes.bool.isRequired,
  isFeesAndIncentivesToggleAnd: PropTypes.bool.isRequired,
  isDesignAndFunctionalityToggleAnd: PropTypes.bool.isRequired,
  isProductOfferingsToggleAnd: PropTypes.bool.isRequired,
  isAccessibilityToggleAnd: PropTypes.bool.isRequired,
  isBrandEquityToggleAnd: PropTypes.bool.isRequired,
  isCustomerToggleAnd: PropTypes.bool.isRequired,
  isProductToggleAnd: PropTypes.bool.isRequired,
  handleResetFilter: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleExpandAll: PropTypes.func.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  handleAndOrChange: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  checkSelections: PropTypes.func.isRequired,
  checkSelectionsChanged: PropTypes.func.isRequired,
  savedFilterSet: PropTypes.object.isRequired,
  setSavedFilterSet: PropTypes.func.isRequired,
  mapValuesToTitles: PropTypes.func.isRequired,
  mapTimesToObject: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  callbackTopics: PropTypes.func.isRequired,
  openTopicPanels: PropTypes.array.isRequired,
}
