/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Button } from 'antd'
import { Auth } from 'aws-amplify'
import _ from 'lodash'
import Swal from 'sweetalert2'

import { topicdatas } from '../../pages/DriversPage/drivers-topics'
import { landing, trendingLanding } from '../../pages/TrendsPage/trends-data'

import { ModalComponent } from '../../components'

import { DataContext, FiltersContext } from '../../providers'

import { usersAPI } from '../../api'

import {
  capitalize,
  checkForMultipleTopics,
  findSubTopicChildren, // formatFIs,
  // formatQuarterlyAggData,
  // formatQuarterlyClassData,
  formatTrendingColumns, // formatYearlyAggData,
  // formatYearlyClassData,
  lowercase,
  mapValuesToTitles,
  topicDriversData,
  yearQuarterDecimal,
} from '../../common/utils/helpers'
import './modal-filters.css'

export const ModalFilters = () => {
  const ref = useRef(null)
  // const ws = useRef(null)
  const {
    setTopicSelections,
    sources,
    stars,
    classifications,
    sizes,
    topics,
    customerServiceValues,
    feesAndIncentivesValues,
    productOfferingsValues,
    designAndFunctionalityValues,
    brandEquityValues,
    accessibilityValues,
    setTopicTitle,
    topicFilterValues,
    setTopicFilterValues,
    setSelectedTopics,
    // attributes,
    customer_attrs,
    product_attrs,
    customerValues,
    productValues,
    attributeFilterValues,
    setAttributeFilterValues,
    toggleValues,
    setToggleValues,
    filterSelection,
    setTagSelection,
    setFilterSelection,
    setOpenPanels,
    isTrendsMentionRate,
    // setFIs,
    sourceValues,
    setSourceValues,
    starValues,
    setStarValues,
    classificationValues,
    setClassificationValues,
    sizeValues,
    setSizeValues,
    timeValues,
    setTimeValues,
    timeFlag,
    setTimeFlag,
    setYearRange,
    setQuarterRange,
    setSliderValue,
    yearRange,
    quarterRange,
    // setValue,
    // setChosenFIs,
    resetFilterData,
  } = useContext(FiltersContext)
  const {
    // trendsData,
    setTrendsTrendingData,
    setResp,
    setIsScorecardCallActive,
    setTopicsData,
    setDatatableData,
    setIsDatatableCallActive,
    setIsForumsCallActive,
    landingDatatableData,
    setIsDirection,
    setIsDriversCallActive,
    setDriversData,
    landingDriversData,
    landingDriversDirection,
    // setTrendsData,
    // isPXIClassification,
    // setTrendsState,
    // trendsState,
    // landingTrendsData,
    setIsTrendsCallActive,
    setIsReviewsCallActive,
    setIsTopicsCallActive,
  } = useContext(DataContext)

  const { pathname } = useLocation()
  const [savedFilterSet, setSavedFilterSet] = useState({
    name: '',
    filterSelection: {},
  })
  const [state, setState] = useState({
    isExpanded: false,
    isModalOpen: false,
    isCheckboxDisabled: false,
    openTopicPanels: [],
  })

  const { isModalOpen, isExpanded, isCheckboxDisabled, openTopicPanels } = state

  useEffect(() => {
    // if (_.isEmpty(filterSelection)) {
    //   setFilterSelection({
    //     sources: mapValuesToTitles(sourceValues, sources),
    //     classifications: mapValuesToTitles(classificationValues, classifications),
    //     sizes: mapValuesToTitles(sizeValues, sizes),
    //     time: mapTimesToObject(),
    //     customerService: mapValuesToTitles(customerServiceValues, findSubTopicChildren(topics, 'Customer Service')),
    //     feesAndIncentives: mapValuesToTitles(feesAndIncentivesValues, findSubTopicChildren(topics, 'Fees And Incentives')),
    //     productOfferings: mapValuesToTitles(productOfferingsValues, findSubTopicChildren(topics, 'Product Offerings')),
    //     accessibility: mapValuesToTitles(accessibilityValues, findSubTopicChildren(topics, 'Accessibility')),
    //     brandEquity: mapValuesToTitles(brandEquityValues, findSubTopicChildren(topics, 'Brand Equity')),
    //     designAndFunctionality: mapValuesToTitles(designAndFunctionalityValues, findSubTopicChildren(topics, 'Design And Functionality')),
    //     customer: mapValuesToTitles(customerValues, customer_attrs),
    //     product: mapValuesToTitles(productValues, product_attrs),
    //     isCustomerServiceToggleAnd: toggleValues.isCustomerServiceToggleAnd,
    //     isFeesAndIncentivesToggleAnd: toggleValues.isFeesAndIncentivesToggleAnd,
    //     isDesignAndFunctionalityToggleAnd: toggleValues.isDesignAndFunctionalityToggleAnd,
    //     isProductOfferingsToggleAnd: toggleValues.isProductOfferingsToggleAnd,
    //     isAccessibilityToggleAnd: toggleValues.isAccessibilityToggleAnd,
    //     isBrandEquityToggleAnd: toggleValues.isBrandEquityToggleAnd,
    //     isCustomerToggleAnd: toggleValues.isCustomerToggleAnd,
    //     isProductToggleAnd: toggleValues.isProductToggleAnd,
    //     pathname,
    //     isTrendsMentionRate,
    //     isMultipleTopics: checkForMultipleTopics(customerServiceValues, feesAndIncentivesValues, productOfferingsValues, accessibilityValues, brandEquityValues, designAndFunctionalityValues),
    //   })
    // }
    resetFilterData()
  }, [])

  useEffect(() => {
    // ws.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL)

    if (Object.keys(savedFilterSet.filterSelection).length > 0) {
      // console.log(savedFilterSet.filterSelection)
      handleOpenSaveModal()
    }

    if (Object.keys(filterSelection).length === 0) {
      setTopicSelections({})
    }

    if (Object.keys(filterSelection).length > 0) {
      handleFiltersContext()
    }

    if (timeValues.length === 0) {
      clearTimesClass()
    }
  }, [filterSelection, savedFilterSet])

  /* MODAL FN'S */
  const showModal = () => {
    setState({ ...state, isModalOpen: true })
  }

  const handleOk = () => {
    setTimeout(() => {
      setState({ ...state, isModalOpen: false })
    }, 3000)
  }

  const handleCancel = () => {
    setState({ ...state, isModalOpen: false })
  }

  /* SUBMIT BUTTON FN'S */
  const handleSubmit = (event) => {
    event.preventDefault()
    setFilterSelection({
      sources: mapValuesToTitles(sourceValues, sources),
      stars: mapValuesToTitles(starValues, stars),
      classifications: mapValuesToTitles(classificationValues, classifications),
      sizes: mapValuesToTitles(sizeValues, sizes),
      time: mapTimesToObject(),
      customerService: mapValuesToTitles(customerServiceValues, findSubTopicChildren(topics, 'Customer Service')),
      feesAndIncentives: mapValuesToTitles(feesAndIncentivesValues, findSubTopicChildren(topics, 'Fees And Incentives')),
      productOfferings: mapValuesToTitles(productOfferingsValues, findSubTopicChildren(topics, 'Product Offerings')),
      accessibility: mapValuesToTitles(accessibilityValues, findSubTopicChildren(topics, 'Accessibility')),
      brandEquity: mapValuesToTitles(brandEquityValues, findSubTopicChildren(topics, 'Brand Equity')),
      designAndFunctionality: mapValuesToTitles(designAndFunctionalityValues, findSubTopicChildren(topics, 'Design And Functionality')),
      customer: mapValuesToTitles(customerValues, customer_attrs),
      product: mapValuesToTitles(productValues, product_attrs),
      isCustomerServiceToggleAnd: toggleValues.isCustomerServiceToggleAnd,
      isFeesAndIncentivesToggleAnd: toggleValues.isFeesAndIncentivesToggleAnd,
      isDesignAndFunctionalityToggleAnd: toggleValues.isDesignAndFunctionalityToggleAnd,
      isProductOfferingsToggleAnd: toggleValues.isProductOfferingsToggleAnd,
      isAccessibilityToggleAnd: toggleValues.isAccessibilityToggleAnd,
      isBrandEquityToggleAnd: toggleValues.isBrandEquityToggleAnd,
      isCustomerToggleAnd: toggleValues.isCustomerToggleAnd,
      isProductToggleAnd: toggleValues.isProductToggleAnd,
      pathname,
      isTrendsMentionRate,
      isMultipleTopics: checkForMultipleTopics(customerServiceValues, feesAndIncentivesValues, productOfferingsValues, accessibilityValues, brandEquityValues, designAndFunctionalityValues),
    })
    setTagSelection({
      sources: mapValuesToTitles(sourceValues, sources),
      stars: mapValuesToTitles(starValues, stars),
      classifications: mapValuesToTitles(classificationValues, classifications),
      sizes: mapValuesToTitles(sizeValues, sizes),
      time: timeValues,
      customerService: mapValuesToTitles(customerServiceValues, findSubTopicChildren(topics, 'Customer Service')),
      feesAndIncentives: mapValuesToTitles(feesAndIncentivesValues, findSubTopicChildren(topics, 'Fees And Incentives')),
      productOfferings: mapValuesToTitles(productOfferingsValues, findSubTopicChildren(topics, 'Product Offerings')),
      accessibility: mapValuesToTitles(accessibilityValues, findSubTopicChildren(topics, 'Accessibility')),
      brandEquity: mapValuesToTitles(brandEquityValues, findSubTopicChildren(topics, 'Brand Equity')),
      designAndFunctionality: mapValuesToTitles(designAndFunctionalityValues, findSubTopicChildren(topics, 'Design And Functionality')),
      customer: mapValuesToTitles(customerValues, customer_attrs),
      product: mapValuesToTitles(productValues, product_attrs),
    })
    setState({
      ...state,
      isModalOpen: false,
      isExpanded: false,
    })

    switch (pathname) {
      case '/data':
        setIsDatatableCallActive(true)
        break
      case '/forums':
        setIsForumsCallActive(true)
        break
      case '/drivers':
        setIsDriversCallActive(true)
        break
      case '/reviews':
        setIsReviewsCallActive(true)
        break
      case '/scorecard':
        setIsScorecardCallActive(true)
        break
      case '/topics':
        setIsTopicsCallActive(true)
        break
      case '/trends':
        setIsTrendsCallActive(true)
        break
      case '/pxitrends':
        setIsTrendsCallActive(true)
        break
      case '/mentionratetrends':
        setIsTrendsCallActive(true)
        break
      default:
        return
    }
    if (pathname !== '/reviews') {
      // setValue([])
      // setChosenFIs([])
    }
  }

  //determines if any topics are selected and toggles isFiltersActive true/false for Topics page
  const handleTopicSelection = (obj) => {
    let newObj
    const { customerService, designAndFunctionality, feesAndIncentives, productOfferings, accessibility, brandEquity } = obj
    if (_.isEmpty(customerService) && _.isEmpty(designAndFunctionality) && _.isEmpty(feesAndIncentives) && _.isEmpty(productOfferings) && _.isEmpty(accessibility) && _.isEmpty(brandEquity)) {
      newObj = {}
    } else {
      newObj = {
        customerService,
        designAndFunctionality,
        feesAndIncentives,
        productOfferings,
        accessibility,
        brandEquity,
      }
    }
    return newObj
  }

  const handleFiltersContext = async () => {
    const resp = await handleTopicSelection(filterSelection)
    setTopicSelections(resp)
  }

  const { finalData } = topicDriversData(topicdatas)

  /* FILTER SELECTION FN'S */
  const handleResetFilter = () => {
    const today = new Date()
    const fiveYearsAgo = today.getFullYear() - 5
    // setYearRange([new Date().getFullYear() - 5, new Date().getFullYear()])
    setYearRange([fiveYearsAgo, yearQuarterDecimal(today)])
    setQuarterRange(['Q1', 'Q4'])
    setSliderValue([fiveYearsAgo, yearQuarterDecimal(today)])
    // setFilterSelection({})
    // setTagSelection({})
    setSourceValues([])
    setStarValues([])
    setTopicTitle('')
    setTopicFilterValues({
      ...topicFilterValues,
      customerServiceValues: [],
      designAndFunctionalityValues: [],
      feesAndIncentivesValues: [],
      productOfferingsValues: [],
      accessibilityValues: [],
      brandEquityValues: [],
      // customerValues: [],
      // productValues: [],
    })
    setAttributeFilterValues({
      ...attributeFilterValues,
      productValues: [],
      customerValues: [],
    })
    setTimeValues([])
    setSizeValues([])
    setClassificationValues([])
    setToggleValues({
      ...toggleValues,
      isAccessibilityToggleAnd: false,
      isFeesAndIncentivesToggleAnd: false,
      isCustomerServiceToggleAnd: false,
      isProductOfferingsToggleAnd: false,
      isBrandEquityToggleAnd: false,
      isCustomerToggleAnd: false,
      isProductToggleAnd: false,
      isDesignAndFunctionalityToggleAnd: false,
    })
    switch (pathname) {
      case '/data':
        // setIsDatatableCallActive(true)
        // setDatatableData(landingDatatableData)
        // setResp(landingDatatableData)
        // setFIs(formatFIs(landingDatatableData))
        break
      case '/drivers':
        // setDriversData(finalData)
        // setIsDirection(landingDriversDirection)
        // setResp(landingDriversData)
        // setFIs(formatFIs(landingDriversData))
        break
      case '/reviews':
        // setIsReviewsCallActive(true)
        break
      case '/forums':
        // setIsForumsCallActive(true)
        break
      case '/scorecard':
        // setIsScorecardCallActive(true)
        break
      case '/topics':
        // setIsTopicsCallActive(true)
        // setTopicsData([])
        // setFIs([])
        break
      case '/trends':
        // console.log('ttttttttttttttttttttt')
        if (isTrendsMentionRate) {
          // setFIs([])
        } else {
          // console.log(landing)
          // setTrendsTrendingData(formatTrendingColumns(trendingLanding))
          // setFIs(formatFIs(landingTrendsData))
          // setTrendsData(landing)
          // setResp(landing)
          // if (isPXIClassification) {
          //   console.log('uuuuuuuuuuuuuuuuu', landing)
          //   setTrendsState({
          //     ...trendsState,
          //     pxiData: formatQuarterlyClassData(landing, 'PXI'),
          //     pxiYearlyData: formatYearlyClassData(landing, 'PXI'),
          //   })
          // } else {
          //   console.log('wwwwwwwwwwwww', landing)
          //   setTrendsState({
          //     ...trendsState,
          //     pxiData: formatQuarterlyAggData(landing, 'PXI'),
          //     pxiYearlyData: formatYearlyAggData(landing, 'PXI'),
          //     isPXIAgg: true,
          //     ixPXIFI: false,
          //     linePXIArr: ['PXI'],
          //     chartLabel: 'Aggregation',
          //   })
          // }
        }
        break
      case '/pxitrends':
        // setIsTopicsCallActive(true)
        // setTopicsData([])
        // setFIs([])
        break
      case '/mentionratetrends':
        // setIsTopicsCallActive(true)
        // setTopicsData([])
        // setFIs([])
        break
      default:
        break
    }
  }

  /* TOGGLE FN'S */
  const handleAndOrChange = (value, filterType) => {
    setToggleValues({
      ...toggleValues,
      [`is${capitalize(filterType)}ToggleAnd`]: value,
    })
  }

  /* COLLAPSE FN'S */
  const callback = (key, error) => {
    setOpenPanels(key)
    if (error) {
      console.warn(key)
    }
  }

  const callbackTopics = (key, error) => {
    setState({ ...state, openTopicPanels: key })
    if (error) {
      console.warn(key)
    }
  }

  const handleExpandAll = (e) => {
    const text = e.target.innerHTML
    if (text === 'Expand All') {
      setState({
        ...state,
        openTopicPanels: ['0', '1', '2', '3', '4', '5', '6', '7'],
        isExpanded: true,
      })
      setOpenPanels(['1', '2', '3', '4', '5', '6', '7'])
    } else {
      setState({
        ...state,
        openTopicPanels: [],
        isExpanded: false,
      })
      setOpenPanels([])
    }
  }

  /* ON CHANGE FOR CHECKBOXES */
  const handleSelectAll = (event, filterType, filterData) => {
    const { textContent } = event.target
    if (textContent === 'Select all') {
      const values = []
      for (let i = 0; i < filterData.length; i++) {
        values.push(i.toString())
      }
      if (filterType === 'source') {
        setSourceValues(values)
      } else if (filterType === 'star') {
        setStarValues(values)
      } else if (
        filterType === 'customerService' ||
        filterType === 'designAndFunctionality' ||
        filterType === 'feesAndIncentives' ||
        filterType === 'productOfferings' ||
        filterType === 'accessibility' ||
        filterType === 'brandEquity'
      ) {
        setTopicFilterValues({ ...topicFilterValues, [`${filterType}Values`]: values })
      } else if (filterType === 'product' || filterType === 'customer') {
        setAttributeFilterValues({ ...attributeFilterValues, [`${filterType}Values`]: values })
      } else if (filterType === 'classification') {
        setClassificationValues(values)
      } else if (filterType === 'size') {
        setSizeValues(values)
      } else {
        setState({
          ...state,
          [`${filterType}Values`]: values,
        })
      }
    } else {
      if (filterType === 'source') {
        setSourceValues([])
      } else if (filterType === 'star') {
        setStarValues([])
      } else if (
        filterType === 'customerService' ||
        filterType === 'designAndFunctionality' ||
        filterType === 'feesAndIncentives' ||
        filterType === 'productOfferings' ||
        filterType === 'accessibility' ||
        filterType === 'brandEquity'
      ) {
        setTopicFilterValues({ ...topicFilterValues, [`${filterType}Values`]: [] })
      } else if (filterType === 'product' || filterType === 'customer') {
        setAttributeFilterValues({ ...attributeFilterValues, [`${filterType}Values`]: [] })
      } else if (filterType === 'classification') {
        setClassificationValues([])
      } else if (filterType === 'size') {
        setSizeValues([])
      } else {
        setState({
          ...state,
          [`${filterType}Values`]: [],
        })
      }
    }
  }

  const handleCheckboxChange = (list, filterType, category) => {
    if (filterType === 'source') {
      setSourceValues(list)
    } else if (filterType === 'star') {
      setStarValues(list)
    } else if (
      filterType === 'customerService' ||
      filterType === 'designAndFunctionality' ||
      filterType === 'feesAndIncentives' ||
      filterType === 'productOfferings' ||
      filterType === 'accessibility' ||
      filterType === 'brandEquity'
    ) {
      setTopicFilterValues({ ...topicFilterValues, [`${filterType}Values`]: list })
    } else if (category != 'topics' && (filterType === 'product' || filterType === 'customer')) {
      setAttributeFilterValues({ ...attributeFilterValues, [`${filterType}Values`]: list })
    } else if (category === 'topics' && (filterType === 'product' || filterType === 'customer')) {
      setTopicFilterValues({ ...topicFilterValues, [`${filterType}Values`]: list })
    } else if (filterType === 'classification') {
      setClassificationValues(list)
    } else if (filterType === 'size') {
      setSizeValues(list)
    } else if (filterType === 'productCustomer') {
      setAttributeFilterValues(list)
    } else if (filterType === 'topic') {
      setTopicFilterValues(list)
    } else {
      setState({
        ...state,
        [`${filterType}Values`]: list,
      })
    }
  }

  /* SAVE FILTER SETS FNS */
  const handleOpenSaveModal = async () => {
    const { value } = await Swal.fire({
      title: 'Enter a name for this filter set',
      input: 'text',
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'Please enter a name'
        }
      },
    })

    if (value) {
      let user = await Auth.currentAuthenticatedUser()
      const {
        attributes: { email },
      } = user

      try {
        await usersAPI.putUserFilterSets({ ...savedFilterSet, name: value }, email)

        const pEl1 = document.createElement('p')
        pEl1.innerHTML = 'Go to the <a href="/settings">settings</a> page to update or delete your filter sets.'

        Swal.fire('Filter set has been saved!', pEl1, 'success')

        setSavedFilterSet({
          name: '',
          filterSelection: {},
        })
      } catch (err) {
        const pEl2 = document.createElement('p')
        pEl2.innerHTML = 'Please try again or contact <a href="mailto:support@hearfulhub.com">support@hearfulhub.com</a>.'

        Swal.fire('Something went wrong.', pEl2, 'error')
      }
    }
  }

  /* TIME TABS FNS */
  const mapTimesToObject = () => {
    if (ref.current) {
      const yearRow = document.querySelector('#year-row')
      const quarterRow = document.querySelector('#quarter-row')
      const monthRowOne = document.querySelector('#month-one-row')
      const monthRowTwo = document.querySelector('#month-two-row')

      const yearData = []
      const quarterData = []
      const monthData = []

      const handleSelectedButtons = (valArr, dataArr) => {
        dataArr.map((el) => {
          if (el.firstChild.classList.contains('timeSelected')) {
            valArr.push(el.firstChild.firstChild.innerHTML)
          }
        })
      }

      /* capturing year data */
      if (yearRow) {
        const yearArr = Array.from(yearRow.children)
        handleSelectedButtons(yearData, yearArr)
      }

      /* capturing quarter data */
      if (quarterRow) {
        const quarterArr = Array.from(quarterRow.children)
        handleSelectedButtons(quarterData, quarterArr)
      }

      /* capturing month data */
      if (monthRowOne) {
        const monthOneArr = Array.from(monthRowOne.children)
        const monthTwoArr = Array.from(monthRowTwo.children)
        const mergedArr = monthOneArr.concat(monthTwoArr)
        handleSelectedButtons(monthData, mergedArr)
      }

      const dataArr = yearData.concat(quarterData).concat(monthData)
      setTimeValues(dataArr)
      const timeObj = {
        year: yearData,
        quarter: quarterData,
        month: monthData,
      }

      if (timeObj.year.length === 0 && timeObj.quarter.length === 0 && timeObj.month.length === 0) {
        return {}
      } else {
        return timeObj
      }
    } else {
      let startDate
      if (quarterRange[0] === 'Q1') {
        startDate = `${Math.floor(yearRange[0])}-01-01` // January 1st
      } else if (quarterRange[0] === 'Q2') {
        startDate = `${Math.floor(yearRange[0])}-04-01` // April 1st
      } else if (quarterRange[0] === 'Q3') {
        startDate = `${Math.floor(yearRange[0])}-07-01` // July 1st
      } else if (quarterRange[0] === 'Q4') {
        startDate = `${Math.floor(yearRange[0])}-10-01` // October 1st
      }
      let endDate
      if (quarterRange[1] === 'Q1') {
        endDate = `${Math.floor(yearRange[1])}-04-01` // April 1st
      } else if (quarterRange[1] === 'Q2') {
        endDate = `${Math.floor(yearRange[1])}-07-01` // July 1st
      } else if (quarterRange[1] === 'Q3') {
        endDate = `${Math.floor(yearRange[1])}-10-01` // October 1st
      } else if (quarterRange[1] === 'Q4') {
        endDate = `${Math.floor(yearRange[1]) + 1}-01-01` // January 1st, next year
      }
      return {
        beforetimepage: 'reviews',
        startDate: startDate,
        endDate: endDate,
        startYear: yearRange[0],
        startQuarter: quarterRange[0],
        endYear: yearRange[1],
        endQuarter: quarterRange[1],
      }
    }
  }

  const clearTimesClass = () => {
    const handleBtnClassChange = (arr) => {
      arr.map((el) => {
        el.firstChild.classList.remove('timeSelected')
      })
    }
    if (ref.current) {
      const yearRow = document.querySelector('#year-row')
      const quarterRow = document.querySelector('#quarter-row')
      const monthRowOne = document.querySelector('#month-one-row')
      const monthRowTwo = document.querySelector('#month-two-row')

      if (yearRow) {
        const yearArr = Array.from(yearRow.children)
        handleBtnClassChange(yearArr)
      }

      if (quarterRow) {
        const quarterArr = Array.from(quarterRow.children)
        handleBtnClassChange(quarterArr)
      }

      if (monthRowOne) {
        const monthArrOne = Array.from(monthRowOne.children)
        const monthArrTwo = Array.from(monthRowTwo.children)
        const mergedArr = monthArrOne.concat(monthArrTwo)
        handleBtnClassChange(mergedArr)
      }
    }
    setTimeValues(timeValues)
  }

  /* CHECKS TO SEE IF ANYTHING IS SELECTED IN MODAL */
  const checkSelections = () => {
    if (
      sourceValues.length > 0 ||
      starValues.length > 0 ||
      classificationValues.length > 0 ||
      sizeValues.length > 0 ||
      customerServiceValues.length > 0 ||
      feesAndIncentivesValues.length > 0 ||
      productOfferingsValues.length > 0 ||
      accessibilityValues.length > 0 ||
      brandEquityValues.length > 0 ||
      customerValues.length > 0 ||
      productValues.length > 0 ||
      timeValues.length > 0 ||
      designAndFunctionalityValues.length > 0 ||
      timeFlag == 1
    ) {
      return true
    } else {
      return false
    }
  }

  const checkSelectionsChanged = () => {
    if (
      _.isEqual(filterSelection, {
        sources: mapValuesToTitles(sourceValues, sources),
        stars: mapValuesToTitles(starValues, stars),
        classifications: mapValuesToTitles(classificationValues, classifications),
        sizes: mapValuesToTitles(sizeValues, sizes),
        time: mapTimesToObject(),
        customerService: mapValuesToTitles(customerServiceValues, findSubTopicChildren(topics, 'Customer Service')),
        feesAndIncentives: mapValuesToTitles(feesAndIncentivesValues, findSubTopicChildren(topics, 'Fees And Incentives')),
        productOfferings: mapValuesToTitles(productOfferingsValues, findSubTopicChildren(topics, 'Product Offerings')),
        accessibility: mapValuesToTitles(accessibilityValues, findSubTopicChildren(topics, 'Accessibility')),
        brandEquity: mapValuesToTitles(brandEquityValues, findSubTopicChildren(topics, 'Brand Equity')),
        designAndFunctionality: mapValuesToTitles(designAndFunctionalityValues, findSubTopicChildren(topics, 'Design And Functionality')),
        customer: mapValuesToTitles(customerValues, customer_attrs),
        product: mapValuesToTitles(productValues, product_attrs),
        isCustomerServiceToggleAnd: toggleValues.isCustomerServiceToggleAnd,
        isFeesAndIncentivesToggleAnd: toggleValues.isFeesAndIncentivesToggleAnd,
        isDesignAndFunctionalityToggleAnd: toggleValues.isDesignAndFunctionalityToggleAnd,
        isProductOfferingsToggleAnd: toggleValues.isProductOfferingsToggleAnd,
        isAccessibilityToggleAnd: toggleValues.isAccessibilityToggleAnd,
        isBrandEquityToggleAnd: toggleValues.isBrandEquityToggleAnd,
        isCustomerToggleAnd: toggleValues.isCustomerToggleAnd,
        isProductToggleAnd: toggleValues.isProductToggleAnd,
        pathname,
        isTrendsMentionRate,
        isMultipleTopics: checkForMultipleTopics(customerServiceValues, feesAndIncentivesValues, productOfferingsValues, accessibilityValues, brandEquityValues, designAndFunctionalityValues),
      })
    ) {
      return false
    }
    return true
  }

  return (
    <div className="modal-filters-div flex">
      <Button className="filters-button flex align-center" onClick={showModal}>
        Filters
      </Button>
      <ModalComponent
        ref={ref}
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        classificationValues={classificationValues}
        sizeValues={sizeValues}
        timeValues={timeValues}
        isExpanded={isExpanded}
        isCheckboxDisabled={isCheckboxDisabled}
        isCustomerServiceToggleAnd={toggleValues.isCustomerServiceToggleAnd}
        isFeesAndIncentivesToggleAnd={toggleValues.isFeesAndIncentivesToggleAnd}
        isDesignAndFunctionalityToggleAnd={toggleValues.isDesignAndFunctionalityToggleAnd}
        isProductOfferingsToggleAnd={toggleValues.isProductOfferingsToggleAnd}
        isAccessibilityToggleAnd={toggleValues.isAccessibilityToggleAnd}
        isBrandEquityToggleAnd={toggleValues.isBrandEquityToggleAnd}
        isCustomerToggleAnd={toggleValues.isCustomerToggleAnd}
        isProductToggleAnd={toggleValues.isProductToggleAnd}
        handleResetFilter={handleResetFilter}
        handleSubmit={handleSubmit}
        handleExpandAll={handleExpandAll}
        handleSelectAll={handleSelectAll}
        handleAndOrChange={handleAndOrChange}
        handleCheckboxChange={handleCheckboxChange}
        checkSelections={checkSelections}
        checkSelectionsChanged={checkSelectionsChanged}
        savedFilterSet={savedFilterSet}
        setSavedFilterSet={setSavedFilterSet}
        mapValuesToTitles={mapValuesToTitles}
        mapTimesToObject={mapTimesToObject}
        callback={callback}
        callbackTopics={callbackTopics}
        openTopicPanels={openTopicPanels}
      />
    </div>
  )
}
