import { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import {
  BarChartOutlined,
  DashboardOutlined,
  FormOutlined,
  HomeOutlined,
  InsertRowAboveOutlined,
  LogoutOutlined,
  MenuOutlined,
  ReadOutlined,
  ScheduleOutlined,
  SettingOutlined,
  StarOutlined,
} from '@ant-design/icons'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Button, Dropdown, Grid, Menu, Row } from 'antd'
import _ from 'lodash'
import { PropTypes } from 'prop-types'

import { DataContext, FiltersContext, ThemeContext } from '../../providers'

import './navbar.css'

const { useBreakpoint } = Grid

export const Navbar = ({ emailMenuColor, logo }) => {
  const { user, signOut } = useAuthenticator((context) => [context.user])
  const { filterSelection, topicFilterValues, setTopicFilterValues, setOpenPanels, setIsTrendsMentionRate, setSourceValues, chosenFIs, FIs } = useContext(FiltersContext)
  const {
    setIsDatatableCallActive,
    setIsDriversCallActive,
    setIsReviewsCallActive,
    setIsTrendsPageActive,
    setIsTopicsCallActive,
    setIsScorecardCallActive,
    datatableFilterSelection,
    driversFilterSelection,
    scorecardFilterSelection,
    reviewsFilterSelection,
    topicsFilterSelection,
  } = useContext(DataContext)
  const { setUserInfo } = useContext(ThemeContext)

  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [activePage, setActivePage] = useState('')
  const { xs } = useBreakpoint()

  useEffect(() => {
    switchActivePage(pathname)
  }, [pathname])

  const switchActivePage = (pathname) => {
    switch (pathname) {
      case '/':
        setActivePage('')
        setIsTrendsMentionRate(false)
        setIsTrendsPageActive(false)
        break
      case '/data':
        setActivePage('Data Table')
        setIsTrendsMentionRate(false)
        setIsTrendsPageActive(false)
        // setValue([])
        setSourceValues([])
        // setChosenFIs([])
        if (!_.isEmpty(filterSelection) && !_.isEqual(filterSelection, datatableFilterSelection)) setIsDatatableCallActive(true)
        break
      case '/drivers':
        setActivePage('Drivers')
        setIsTrendsMentionRate(false)
        setIsTrendsPageActive(false)
        setTopicFilterValues({
          ...topicFilterValues,
          customerServiceValues: [],
          feesAndIncentivesValues: [],
          productOfferingsValues: [],
          brandEquityValues: [],
          accessibilityValues: [],
          designAndFunctionalityValues: [],
          // customerValues: [],
          // productValues: [],
        })
        if (!_.isEmpty(filterSelection) && !_.isEqual(filterSelection, driversFilterSelection)) setIsDriversCallActive(true)
        break
      case '/forums':
        setActivePage('Forum Mining')
        setIsTrendsMentionRate(false)
        setIsTrendsPageActive(false)
        // setValue([])
        // setChosenFIs([])
        break
      case '/reviews':
        setActivePage('Review Mining')
        setIsTrendsMentionRate(false)
        setIsTrendsPageActive(false)
        if ((!_.isEmpty(filterSelection) && !_.isEqual(filterSelection, reviewsFilterSelection)) || (!_.isEmpty(chosenFIs) && !_.isEqual(chosenFIs, FIs))) setIsReviewsCallActive(true)
        break
      case '/scorecard':
        setActivePage('Scorecard')
        setIsTrendsMentionRate(false)
        setIsTrendsPageActive(false)
        // setValue([])
        // setChosenFIs([])
        setIsScorecardCallActive(true)
        if (!_.isEmpty(filterSelection) && !_.isEqual(filterSelection, scorecardFilterSelection)) setIsScorecardCallActive(true)
        break
      case '/settings':
        setActivePage('Settings')
        setIsTrendsMentionRate(false)
        setIsTrendsPageActive(false)
        break
      case '/topics':
        setIsTrendsPageActive(false)
        setActivePage('Topics')
        setOpenPanels(['6'])
        // setValue([])
        // setChosenFIs([])
        if (!_.isEmpty(filterSelection) && !_.isEqual(filterSelection, topicsFilterSelection)) setIsTopicsCallActive(true)
        break
      case '/trends':
        setActivePage('Trends')
        setIsTrendsMentionRate(false)
        setIsTrendsPageActive(true)
        // setValue([])
        // setChosenFIs([])
        // if (!_.isEmpty(filterSelection) && !_.isEqual(filterSelection, trendsFilterSelection)) setIsTrendsCallActive(true)
        break
      case '/pxitrends':
        setActivePage('PXI Trends')
        // setIsTrendsMentionRate(false)
        // setIsTrendsPageActive(true)
        break
      case '/mentionratetrends':
        setActivePage('Mention Rate Trends')
        // setIsTrendsMentionRate(false)
        // setIsTrendsPageActive(true)
        break
      default:
        setActivePage('')
        setIsTrendsMentionRate(false)
        setIsTrendsPageActive(false)
    }
  }

  const handleNavChange = (value) => {
    if (typeof value === 'string') {
      navigate(`/${value}`, { state: { prevPages: [{ pageTitle: 'Home', pagePath: '/' }] } })
      switchActivePage(`/${value}`)
    } else {
      navigate(`/${value.key}`, { state: { prevPages: [{ pageTitle: 'Home', pagePath: '/' }] } })
      switchActivePage(`/${value.key}`)
    }
  }

  const onMenuClick = (e) => {
    if (e.key === 'logout') {
      handleLogoutClick()
    }
    if (e.key === 'settings') {
      handleNavChange(e)
    }
  }

  const handleLogoutClick = () => {
    // Remove saved username data from localStorage
    localStorage.removeItem('username')
    setUserInfo({})
    signOut()
    navigate('/login')
    switchActivePage('/login')
  }

  const menu = (
    <Menu
      style={{ padding: '10px 10px', borderRadius: '10px' }}
      className="username-menu float-right"
      trigger={['click']}
      onClick={onMenuClick}
      items={[
        {
          key: 'logout',
          icon: <LogoutOutlined />,
          label: 'Logout',
        },
        {
          key: 'settings',
          icon: <SettingOutlined />,
          label: 'Settings',
        },
      ]}
    >
      {/* <Menu.Item key="logout" onClick={handleLogoutClick}>
        <LogoutOutlined /> Logout
      </Menu.Item>
      <Menu.Item key="settings" value="settings" onClick={handleNavChange}>
        <SettingOutlined /> Settings
      </Menu.Item> */}
    </Menu>
  )

  const mainMenu = (
    <Menu
      key={activePage}
      className="main-menu"
      style={{ padding: '10px 10px', textAlign: 'left', borderRadius: '10px' }}
      onClick={handleNavChange}
      items={[
        {
          key: '',
          icon: <HomeOutlined style={{ fontSize: '18px', padding: '5px 0px' }} />,
          label: 'Home',
        },
        {
          key: 'scorecard',
          icon: <ScheduleOutlined style={{ fontSize: '18px', padding: '5px 0px' }} />,
          label: 'Scorecard',
        },
        {
          key: 'topics',
          icon: <ReadOutlined style={{ fontSize: '18px', padding: '5px 0px' }} />,
          label: 'Topics',
        },
        {
          key: 'trends',
          icon: <DashboardOutlined style={{ fontSize: '18px', padding: '5px 0px' }} />,
          label: 'Trends',
        },
        {
          key: 'pxitrends',
          icon: <DashboardOutlined style={{ fontSize: '18px', padding: '5px 0px' }} />,
          label: 'PXI Trends',
        },
        {
          key: 'mentionratetrends',
          icon: <DashboardOutlined style={{ fontSize: '18px', padding: '5px 0px' }} />,
          label: 'Mention Rate Trends',
        },
        {
          key: 'data',
          icon: <InsertRowAboveOutlined style={{ fontSize: '18px', padding: '5px 0px' }} />,
          label: 'Data Table',
        },
        {
          key: 'reviews',
          icon: <StarOutlined style={{ fontSize: '18px', padding: '5px 0px' }} />,
          label: 'Reviews',
        },
        {
          key: 'drivers',
          icon: <BarChartOutlined style={{ fontSize: '18px', padding: '5px 0px' }} />,
          label: 'Drivers',
        },
        {
          key: 'forums',
          icon: <FormOutlined style={{ fontSize: '18px', padding: '5px 0px' }} />,
          label: 'Forums',
        },
      ]}
    />
  )

  const fullMenu = (
    <Menu
      key={activePage}
      className="main-menu"
      style={{ padding: '10px 10px', textAlign: 'left', borderRadius: '10px' }}
      onClick={handleNavChange}
      items={[
        {
          key: '',
          icon: <HomeOutlined style={{ fontSize: '18px', padding: '5px 0px' }} />,
          label: 'Home',
        },
        {
          key: 'scorecard',
          icon: <ScheduleOutlined style={{ fontSize: '18px', padding: '5px 0px' }} />,
          label: 'Scorecard',
        },
        {
          key: 'topics',
          icon: <ReadOutlined style={{ fontSize: '18px', padding: '5px 0px' }} />,
          label: 'Topics',
        },
        {
          key: 'trends',
          icon: <DashboardOutlined style={{ fontSize: '18px', padding: '5px 0px' }} />,
          label: 'Trends',
        },
        {
          key: 'pxitrends',
          icon: <DashboardOutlined style={{ fontSize: '18px', padding: '5px 0px' }} />,
          label: 'PXI Trends',
        },
        {
          key: 'mentionratetrends',
          icon: <DashboardOutlined style={{ fontSize: '18px', padding: '5px 0px' }} />,
          label: 'Mention Rate Trends',
        },
        {
          key: 'data',
          icon: <InsertRowAboveOutlined style={{ fontSize: '18px', padding: '5px 0px' }} />,
          label: 'Data Table',
        },
        {
          key: 'reviews',
          icon: <StarOutlined style={{ fontSize: '18px', padding: '5px 0px' }} />,
          label: 'Reviews',
        },
        {
          key: 'drivers',
          icon: <BarChartOutlined style={{ fontSize: '18px', padding: '5px 0px' }} />,
          label: 'Drivers',
        },
        {
          key: 'settings',
          label: 'Settings',
          icon: <SettingOutlined style={{ fontSize: '18px', padding: '5px 0px' }} />,
        },
        {
          key: 'logout',
          label: 'Logout',
          icon: <LogoutOutlined style={{ fontSize: '18px', padding: '5px 0px' }} />,
        },
      ]}
    />
  )

  return (
    <Row className="nav-header">
      {!xs ? (
        <div style={{ textAlign: 'left' }}>
          <Dropdown overlay={mainMenu} placement="bottomRight">
            <Button className="main-menu-button">
              <MenuOutlined style={{ fontSize: '24px', color: 'var(--nav-text)' }} />
            </Button>
          </Dropdown>
        </div>
      ) : null}
      <div style={{ textAlign: xs ? 'left' : 'center' }}>
        <div>
          <Link to="/">
            <img width={160} src={logo} alt="logo" />
          </Link>
        </div>
      </div>
      {!_.isEmpty(user?.attributes?.email) ? (
        <div className="flex mt-10" style={{ justifyContent: 'flex-end' }}>
          {!xs ? (
            <Dropdown overlay={menu}>
              <a style={{ color: emailMenuColor }} onClick={(e) => e.preventDefault()}>
                {user?.attributes?.email}
              </a>
            </Dropdown>
          ) : (
            <Dropdown align={{ offset: [-50, 4] }} overlay={fullMenu}>
              <Button className="main-menu-button">
                <MenuOutlined style={{ fontSize: '24px', color: 'var(--nav-text)' }} />
              </Button>
            </Dropdown>
          )}
        </div>
      ) : null}
    </Row>
  )
}

Navbar.propTypes = {
  logo: PropTypes.any.isRequired,
  emailMenuColor: PropTypes.any.isRequired,
}
