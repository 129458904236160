import { useContext, useEffect, useState } from 'react'

import { Card, Layout, Table } from 'antd'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'

import { CheckUserInfo, Loading, PageHeader } from '../../components'

import { DataContext, FiltersContext, ThemeContext } from '../../providers'

import { filtersAPI } from '../../api'

import './data-table-page.css'

const { Content } = Layout

export const DataTablePage = () => {
  const { isDatatableLoading, setIsDatatableLoading, datatableData, setDatatableData, setResp, setIsDatatableCallActive, isDatatableCallActive, setDatatableFilterSelection } = useContext(DataContext)
  const { chosenFIs, setChosenFIs, filterSelection, setFilterSelection, tagSelection, setTagSelection } = useContext(FiltersContext)
  const { userInfo, getChosenFIs } = useContext(ThemeContext)
  const [scrollHeight, setScrollHeight] = useState(window.innerHeight - 0.25 * window.innerHeight - 181)

  // general useEffect, empty callback
  useEffect(() => {
    window.scrollTo(0, 0)

    function resizeHandler() {
      setScrollHeight(window.innerHeight - 0.25 * window.innerHeight - 181)
      setIsDatatableLoading(false)
    }
    window.addEventListener('resize', resizeHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const checkUserInfo = async () => {
    const updatedChosenFIs = await getChosenFIs()
    if (!_.isEmpty(updatedChosenFIs)) {
      setChosenFIs(updatedChosenFIs)
      setIsDatatableLoading(true)
      const sentData = {
        chosenFIs: updatedChosenFIs,
        filterSelection,
      }
      updateDatatableData(sentData)
    } else {
      setIsDatatableLoading(false)
    }
  }

  useEffect(() => {
    checkUserInfo()
  }, [userInfo])

  // useEffect that handles isDatatableCallActive
  useEffect(() => {
    if (!isDatatableCallActive && !_.isEmpty(datatableData)) return

    if (isDatatableCallActive) {
      if (!_.isEmpty(filterSelection) && !_.isEmpty(chosenFIs)) {
        setIsDatatableLoading(true)
        setTagSelection({ ...tagSelection, sources: [] })
        setFilterSelection({ ...filterSelection, sources: [] })
        setDatatableFilterSelection({ ...filterSelection, sources: [] })
        const sentData = {
          filterSelection: { ...filterSelection, sources: [] },
          chosenFIs,
        }
        updateDatatableData(sentData)
      }
    }
  }, [isDatatableCallActive])

  const updateDatatableData = (sentData) => {
    filtersAPI
      .getFilterSelections('/data', sentData)
      .then((response) => {
        if (!_.isEmpty(response)) {
          response.map((item, i) => {
            item.key = i
          })
          setResp(response)
          setDatatableData(response)
          setIsDatatableLoading(false)
          setIsDatatableCallActive(false)
        } else if (_.isEmpty(response)) {
          Swal.fire({
            text: 'Unfortunately these particular Data Table filter selections yielded no results. Please select different filters and try again.',
          })
          setIsDatatableLoading(false)
          setIsDatatableCallActive(false)
          // setFilterSelection({})
        }
      })
      .catch((error) => {
        console.warn(error)
      })
  }

  const columns = [
    {
      title: 'Financial Institutions',
      dataIndex: '_id',
      key: '_id',
      sorter: (a, b) => a._id.localeCompare(b._id),
      className: 'feature-column',
    },
    {
      title: 'Google Play Store',
      dataIndex: 'google_play_store',
      key: 'google_play_store',
      sorter: (a, b) => +a.google_play_store - +b.google_play_store,
      render: (num) => <>{new Intl.NumberFormat().format(num)}</>,
    },
    {
      title: 'App Store',
      dataIndex: 'appstore',
      key: 'appstore',
      sorter: (a, b) => +a.appstore - +b.appstore,
      render: (num) => <>{new Intl.NumberFormat().format(num)}</>,
    },
    {
      title: 'Total No. of Reviews',
      dataIndex: 'total',
      key: 'total',
      defaultSortOrder: 'descend',
      sorter: (a, b) => +a.total - +b.total,
      className: 'feature-column',
      render: (num) => <>{new Intl.NumberFormat().format(num)}</>,
    },
  ]

  return (
    <>
      <Content className="summary-data-page-container page-content">
        <Loading isLoading={isDatatableLoading}>
          <CheckUserInfo>
            <PageHeader
              title="Data Table"
              description="A snapshot of every institution and the amount of reviews they have in each source. It is expected that some sources will not have any reviews for certain institutions. You can sort largest to smallest in every column."
            />
            <div className="data-table-div">
              {datatableData.length > 0 ? (
                <Card className="page-card align-items space-between" bordered={false}>
                  <Table
                    rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
                    scroll={{ y: scrollHeight }}
                    className="data-table"
                    key="data-table"
                    columns={columns}
                    dataSource={datatableData}
                    pagination={false}
                    summary={(data) => {
                      let totalGooPlaSto = 0
                      let totalAppSto = 0
                      let totalTot = 0

                      data.forEach((item) => {
                        totalGooPlaSto += +item.google_play_store
                        totalAppSto += +item.appstore
                        totalTot += +item.total
                      })
                      return (
                        <Table.Summary fixed>
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>{new Intl.NumberFormat().format(totalGooPlaSto)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>{new Intl.NumberFormat().format(totalAppSto)}</Table.Summary.Cell>
                            {/* trying to remove border-right on this cell only */}
                            <Table.Summary.Cell index={3}>{new Intl.NumberFormat().format(totalTot)}</Table.Summary.Cell>
                          </Table.Summary.Row>
                        </Table.Summary>
                      )
                    }}
                  />
                </Card>
              ) : null}
            </div>
          </CheckUserInfo>
        </Loading>
      </Content>
    </>
  )
}

DataTablePage.propTypes = {
  dataSet: PropTypes.array.isRequired,
}
